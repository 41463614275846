import React, { useState } from "react";
import logo from "Public/navBar_images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import "styles/Forms/Signup.css";
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast } from "react-toastify";
import { baseURl } from "Utils/BaseURL";

const Signup = () => {
  const navigate = useNavigate();
  // Error------------
  const [formError, setFormError] = useState({});

  const [signupData, setSignUpData] = useState({
    name: "",
    email: "",
    password: "",
  });

  // password eyes -----------
  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // hangeHandler-----------------
  const changeHandler = (e) => {
    setFormError({});
    const { name, value } = e.target;
    setSignUpData({ ...signupData, [name]: value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    if (
      signupData.name === "" &&
      signupData.email === "" &&
      signupData.password === ""
    ) {
      toast.error("Name,Email and Password is required");
      return;
    }
    if (signupData.name === "") {
      toast.error("Name is required");
      return;
    }
    if (signupData.email === "") {
      toast.error("Email is required");
      return;
    }
    if (signupData.password === "") {
      toast.error("Password is required");
      return;
    }
    if (!validate(signupData)) {
      return;
    }

    try {
      const response = await axios.post(`${baseURl}register`, signupData);
      // console.log("response.............", response);
      if (response.status === 200) {
        navigate("/login");
        toast.success(response?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
      toast.warning(error?.response?.data?.message);
    }
  };

  const validate = (values) => {
    const errors = {};
    const nameRegex = /^(?!\s)[a-zA-Z0-9\s]+$/i;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!nameRegex.test(values.name)) {
      errors.name = "Name should be contain only Corrector";
    }
    if (!emailRegex.test(values.email)) {
      errors.email = "Enter your Correct email";
    }
    if (!passwordRegex.test(values.password)) {
      errors.password =
        "must contain atleast one number,one uppercase,one lowercase letter ,one special character";
    }
    setFormError(errors);

    if (Object.keys(errors).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row Signup d-flex justify-content-center align-items-center">
          <div className="col-md-4 d-flex  align-items-center">
            <div className="Signup_form">
              <div className="card">
                <div className="d-flex justify-content-center align-items-center pb-3">
                  <div className="d-flex justify-content-center align-items-center  avtar ">
                    <img
                      src={logo}
                      alt="img"
                      className="w-100"
                      onClick={() => navigate("/")}
                    />
                  </div>
                </div>
                <h6>Create Account Meteo Compare</h6>
                <form onSubmit={submitHandler}>
                  <label for="name">Name*</label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                    value={signupData.name}
                    onChange={changeHandler}
                  />
                  {formError.name ? (
                    <p className="text-danger text-start fs-6 m-0">
                      Name should be contain only Corrector
                    </p>
                  ) : (
                    ""
                  )}

                  <label for="email">Email address*</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={signupData.email}
                    onChange={changeHandler}
                  />
                  {formError.email ? (
                    <p className="text-danger text-start fs-6 m-0">
                      Enter your Correct email
                    </p>
                  ) : (
                    ""
                  )}
                  <label for="password">Password*</label>
                  <div className="pass_visible">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      value={signupData.password}
                      onChange={changeHandler}
                    />
                    {formError.password ? (
                      <p className="text-danger text-start fs-6 m-0">
                        Minimum eight characters, at least one letter and one
                        number
                      </p>
                    ) : (
                      ""
                    )}
                    <div
                      className="password_toggle"
                      onClick={handleShowPassword}
                    >
                      {showPassword ? (
                        <FaEye className="eyes" />
                      ) : (
                        <FaEyeSlash className="eyes" />
                      )}
                    </div>
                  </div>
                  <button type="submit" className="login_btn">
                    Sign Up
                  </button>
                  <p className="my-md-2">
                    <Link to="/login">Already have an account ?</Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
