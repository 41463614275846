import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../../styles/Profile/ChangePassword.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteAccountApi } from "Apis/MainApis";

function DeleteAccountModal({ showDeleteAccount, setShowDeleteAccount }) {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});

  const resetChangePasswordForm = () => {
    setOldPassword("");
  };

  const validateNewPassword = () => {
    const errors = {};

    if (!oldPassword) {
      errors.oldpassword = "current password cannot be empty";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    if (validationErrors.oldpassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        oldpassword: "",
      }));
    }
  };

  const handleSave = async () => {
    if (validateNewPassword()) {
      const data = {
        currentPassword: oldPassword,
      };

      const response = await deleteAccountApi(data);
      console.log(response.status);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        resetChangePasswordForm();
        setShowDeleteAccount(false);
        localStorage.clear();
        navigate("/login");
      }
    }
  };
  return (
    <Modal
      show={showDeleteAccount}
      onHide={() => setShowDeleteAccount(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="resetpassword_title">
          Delete Account
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="oldPassword">
            <Form.Label className="resetpassword-label m-0 pb-2">
              Current Password
            </Form.Label>
            <div className="resetpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.oldpassword ? "1px solid red" : "",
                  color: validationErrors.oldpassword ? "red" : "",
                }}
                type="text"
                placeholder="current password"
                value={oldPassword}
                className="newpassword-formcontrol"
                onChange={handleOldPasswordChange}
              />
            </div>
            {validationErrors.oldpassword && (
              <div className="oldpassword_error">
                {validationErrors.oldpassword}
              </div>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="resetpassword_button"
          onClick={handleSave}
        >
          Delete Account
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteAccountModal;
