import React from "react";
import "../../styles/Comparison/ComparisonTable.css";

const ComparisonTable = ({ filtersData, products }) => {
  // console.log("products in ComparisonTable", products);
  return (
    <>
      <div className="ComparisonTable table-responsive">
        <table style={{ width: "100%" }} className="table">
          <thead>
            <tr>
              <th className="Comparison">Comparison</th>
              <th className="Comparison">
                {products[0]?.name?.length > 40
                  ? `${products[0]?.name?.slice(0, 40)}...`
                  : products[0]?.name || "Product 1"}
              </th>
              <th className="Comparison">
                {" "}
                {products[1]?.name?.length > 40
                  ? `${products[1]?.name?.slice(0, 40)}...`
                  : products[1]?.name || "Product 2"}
              </th>
              <th className="Comparison">
                {" "}
                {products[2]?.name?.length > 40
                  ? `${products[2]?.name?.slice(0, 40)}...`
                  : products[2]?.name || "Product 3"}
              </th>
              <th className="Comparison">
                {" "}
                {products[3]?.name?.length > 40
                  ? `${products[3]?.name?.slice(0, 40)}...`
                  : products[3]?.name || "Product 4"}
              </th>
            </tr>
          </thead>

          {filtersData?.filters?.map((item, i) => (
            <tr>
              <td className="fw-bold">{item?.filter_name}: </td>
              <td>{products[0]?.[item?.filter_column] || "-"}</td>
              <td>{products[1]?.[item?.filter_column] || "-"}</td>
              <td>{products[2]?.[item?.filter_column] || "-"}</td>
              <td>{products[3]?.[item?.filter_column] || "-"}</td>
            </tr>
          ))}
        </table>
      </div>
    </>
  );
};

export default ComparisonTable;
