import { baseURl } from "Utils/BaseURL";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

export async function allComparisonsProductsDeleteApi() {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(
      `${baseURl}compare-product-delete`,
      {},
      { headers }
    );
    console.log("allComparisonsProductsDeleteApi", response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log(
      "Error in  allComparisonsProductsDelete:",
      error.response.data.message
    );
    return null;
  }
}

export async function fetchSinglePortfolio(data) {
  try {
    const response = await axios.post(`${baseURl}single-portfolio`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchSinglePortfolio:", error.response.data.message);
    return null;
  }
}

export async function fetchPrivacyPolicy(data) {
  try {
    const response = await axios.get(`${baseURl}fetch-privacy-policy`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchAboutUs:", error.response.data.message);
    return null;
  }
}

export async function fetchAboutUs(data) {
  try {
    const response = await axios.get(`${baseURl}fetch-about-us`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchAboutUs:", error.response.data.message);
    return null;
  }
}

export async function fetchTerms(data) {
  try {
    const response = await axios.get(`${baseURl}fetch-terms`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchSupplierApi:", error.response.data.message);
    return null;
  }
}

export async function fetchSupplierApi(data) {
  try {
    const response = await axios.post(`${baseURl}fetch-suppliers`, data);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchSupplierApi:", error.response.data.message);
    return null;
  }
}

export async function addCompareProductsApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}compare-product-store`, data, {
      headers,
    });
    return response;
  } catch (error) {
    toast.warning(error.response.data.message);
    console.log(
      "Error in  addCompareProductsApi:",
      error.response.data.message
    );
    return null;
  }
}

export async function deleteCompareProductsApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(
      `${baseURl}single-compare-product-delete`,
      data,
      { headers }
    );
    console.log("deleteCompareProductsApi", response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log(
      "Error in  deleteCompareProductsApi:",
      error.response.data.message
    );
    return null;
  }
}

export async function fetchcompareProductsApi() {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(
      `${baseURl}fetch-compare-products`,
      {},
      { headers }
    );
    console.log(response);
    return response;
  } catch (error) {
    console.log(
      "Error in  fetchcompareProductsApi:",
      error.response.data.message
    );
    return null;
  }
}

export async function fetchBlogApi() {
  try {
    const response = await axios.post(`${baseURl}fetch-blog-categories`);
    console.log(response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchBlogApi:", error.response.data.message);
    return null;
  }
}

export async function fetchCategoryBlogApi(data, setOpen) {
  setOpen(true);
  try {
    const response = await axios.post(`${baseURl}fetch-category-blogs`, data);
    setOpen(false);
    return response;
  } catch (error) {
    console.log("Error in  fetchBlogApi:", error.response.data.message);
    setOpen(false);
    return null;
  }
}

export async function fetchSingleBlogApi(data) {
  try {
    const response = await axios.post(`${baseURl}single-post`, data);
    console.log(response);
    return response;
  } catch (error) {
    // toast(error.response.data.message);
    console.log("Error in  fetchBlogApi:", error.response.data.message);
    return null;
  }
}

export async function contactUsApi(data) {
  try {
    const response = await axios.post(`${baseURl}contact-us`, data);
    console.log(response);
    return response;
  } catch (error) {
    toast(error.response.data.message);
    console.log("Error in  ContactUsApi:", error.response.data.message);
    return null;
  }
}

export async function deleteAccountApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}delete-account`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    toast.error(error.response.data.message);
    console.log("Error in  deleteAccountApi:", error.response.data.message);
    return null;
  }
}

export async function changePasswordApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}password-change`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    toast(error.response.data.message);
    console.log("Error in  changePasswordApi:", error.response.data.message);
    return null;
  }
}

export const fetchVideoApi = async (data) => {
  try {
    const response = await axios.get(`${baseURl}fetch-videos`, data);
    return response;
  } catch (error) {
    console.log("Error in fetchVideoApi:", error.response);
    return null;
  }
};

export const requestQuoteApi = async (
  data,
  setOpen,
  setFormData,
  setQuoteM
) => {
  const token = localStorage.getItem("Meteor_Key");
  setOpen(true);
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}request-a-quote`, data, {
      headers,
    });
    Swal.fire({
      title: "Meteo",
      text: response?.data?.message,
      icon: "success",
      confirmButtonText: "Ok",
      confirmButtonColor: "#134a80",
    });
    setFormData({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      message: "",
    });
    setQuoteM(false);

    setOpen(false);
    return response;
  } catch (error) {
    setOpen(false);
    toast(error.response.data.message);
    console.log("Error in requestQuoteApi:", error.response);
    return null;
  }
};

export const homeProductApi = async (userId) => {
  try {
    const data = {
      uid: userId || "",
    };
    const response = await axios.post(`${baseURl}fetch-products-home`, data);
    return response;
  } catch (error) {
    console.log("Error in homeProductApi:", error.response);
    return null;
  }
};

export const fetchParentCategories = async (callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURl}fetch-parent-categories`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchProductAp3i = async (data, setOpen, isLoader, callback) => {
  console.log("isLoader in fetchProductAp3i", isLoader);
  if (isLoader) {
    setOpen(true);
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURl}fetch-category-products`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log("response from filterProductsApi", response);
      callback(response.data);
      setOpen(false);
    })
    .catch((error) => {
      console.log(error);
      setOpen(false);
    });
};

export const singleProductApi = async (
  paramid,
  user_id,
  setOpen,
  isLoader,
  callback
) => {
  if (isLoader) {
    setOpen(true);
  }
  let data = JSON.stringify({
    uid: user_id || "",
    productId: paramid,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURl}single-product`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      callback(response.data);
      console.log("response from singleProduct", response);
      setOpen(false);
    })
    .catch((error) => {
      console.log(error);
      setOpen(false);
    });
};

export const featchCategoryProduct = (data, setOpen, isLoader, callback) => {
  // console.log("isLoader in featchCategoryProduct", isLoader);
  if (isLoader) {
    setOpen(true);
  }
  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURl}fetch-category-products`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      // console.log("repsonse from featchCategoryProduct", response);
      // console.log(JSON.stringify(response.data));
      callback(response.data);
      if (isLoader) {
        setOpen(false);
      }
    })
    .catch((error) => {
      // toast(error.response.data.message);
      console.log(error.response);
      setOpen(false);
    });
};

export const fetchWishListApi = async () => {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(
      `${baseURl}fetch-wishlist-products`,
      {},
      { headers }
    );
    return response;
  } catch (error) {
    // console.log("Error in fetchWishListApi:", error.response);
    return null;
  }
};

export const addWishlist = async (id) => {
  const token = localStorage.getItem("Meteor_Key");
  const data = {
    productId: id,
  };
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}add-to-wishlist`, data, {
      headers,
    });
    console.log(response, "addWishlist");
    return response;
  } catch (error) {
    console.log("Error in add WishList Api:", error.response);
    return null;
  }
};

export const removeWishlistApi = async (id) => {
  const token = localStorage.getItem("Meteor_Key");
  const data = {
    productId: id,
  };
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}remove-from-wishlist`, data, {
      headers,
    });
    console.log(response, "removeWishlist");
    return response;
  } catch (error) {
    console.log("Error in remove WishList Api:", error.response);
    return null;
  }
};

export const updateInfoApi = async (data) => {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await axios.post(`${baseURl}info-update`, data, {
      headers,
    });
    return response;
  } catch (error) {
    console.log("Error in updateInfoApi:", error.response);
    return null;
  }
};

export async function profilePicApi(data) {
  const token = localStorage.getItem("Meteor_Key");
  try {
    const headers = {
      Authorization: `Bearer${token}`,
    };
    const response = await axios.post(`${baseURl}profile-update`, data, {
      headers,
    });
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error in  profilePicApi:", error);
    return null;
  }
}

export const RecentlyViewedApi = (token, callback) => {
  let config = {
    method: "post",
    maxBodyLength: Infinity,

    url: `${baseURl}fetch-recently-viewed`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  axios
    .request(config)
    .then((response) => {
      console.log("response from recently view api", response);
      callback(response.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

export const RecentlyViewProduct = (id, token, callback) => {
  let data = JSON.stringify({
    productId: id,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${baseURl}recently-viewed-product`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      console.log("response from recently viewed product", response);
      callback(response.data);
    })
    .catch((error) => {
      // console.log(error);
    });
};

export const searchApi = (uid, query, page, setLoading, callback) => {
  let data = JSON.stringify({
    uid: uid,
    search: query,
    page: page,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,

    url: `${baseURl}search-product`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  axios
    .request(config)
    .then((response) => {
      setLoading(false);
      callback(response.data);
    })
    .catch((error) => {
      // console.log(error);
      setLoading(false);
    });
};
