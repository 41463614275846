import React from "react";
import { FaRegHeart } from "react-icons/fa";
import { Button } from "react-bootstrap";
import "styles/CategoriesNavBar/FilterProductVertical.css";
import { useNavigate } from "react-router-dom";
import { FaHeart } from "react-icons/fa6";
import ReactPaginate from "react-paginate";

const FilterProductVertical = ({
  featchProduct,
  handleWishlist,
  totalPageCount,
  handlePageChange,
  handleCompare = { handleCompare },
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="FilterProductVertical">
        <div className="row  mt-3">
          <div className="col-12">
            {featchProduct?.categoryProducts?.length === 0 ? (
              <>
                <>
                  <div className="d-flex align-items-center">
                    <h2>No items to show</h2>
                  </div>
                </>
              </>
            ) : (
              <>
                {featchProduct?.categoryProducts?.map((item, i) => {
                  return (
                    <div
                      className="row mb-4 FilterProductVertical_card"
                      key={i}
                    >
                      <div className="col-sm-3 p-0">
                        <img
                          src={item.featured_image}
                          alt="img"
                          className="w-100 verticalfilterImage"
                          onClick={() => {
                            navigate(
                              `/detail-page/${item?.slug}_${item?.id}`,
                              window.scrollTo(0, 0)
                            );
                          }}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="col-sm-9 d-flex justify-content-between flex-column FilterProductVertical_card_details">
                        <div>
                          <div class="d-flex align-item-center justify-content-between mb-2">
                            <h5
                              className="text-start m-0 pb-2 fw-bold filter_text"
                              style={{ width: "90%" }}
                            >
                              {item.name}
                            </h5>
                            {item.wishlist === "no" ? (
                              <>
                                <FaRegHeart
                                  className="FilterProductVertical_heart_icon"
                                  onClick={() =>
                                    handleWishlist(item?.id, item?.wishlist)
                                  }
                                />
                              </>
                            ) : (
                              <>
                                <FaHeart
                                  className="FilterProductVertical_heart_icon"
                                  onClick={() =>
                                    handleWishlist(item?.id, item?.wishlist)
                                  }
                                />
                              </>
                            )}
                          </div>
                          <p className="text-start m-0 filter_text">
                            {item.description}
                          </p>
                        </div>
                        <div className="d-flex justify-content-end align-items-end">
                          <div className="d-flex justify-content-end gap-3">
                            <Button
                              type="submit"
                              className="global_button_white "
                              onClick={() => {
                                navigate(
                                  `/detail-page/${item?.slug}_${item?.id}`,
                                  window.scrollTo(0, 0)
                                );
                              }}
                            >
                              Details
                            </Button>
                            <Button
                              type="submit"
                              className="global_button_colored"
                              onClick={() => handleCompare(item)}
                            >
                              Compare
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </div>
      <div className="category_paginate">
        {totalPageCount > 1 && (
          <ReactPaginate
            key={totalPageCount}
            pageCount={totalPageCount}
            breakLabel={"..."}
            pageRangeDisplayed={1} //... ke bech me pages
            marginPagesDisplayed={1} //first and last pages ...
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active-page"}
            previousLabel={"previous"}
            nextLabel={"next"}
          />
        )}
      </div>
    </>
  );
};

export default FilterProductVertical;
