import axios from "axios";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";
import { Link, useNavigate } from "react-router-dom";
import "styles/Forms/Otp.css";
import { baseURl } from "Utils/BaseURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Otp = () => {
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");

  const navigate = useNavigate();

  const verifyOtp = async () => {
    try {
      const data = { otp: otp };
      const OtpVerify = await axios.post(`${baseURl}otp-verification`, data);
      // console.log("fffffffff", OtpVerify);
      if (OtpVerify.data.status === "success") {
        setToken(OtpVerify.data.data.token);
        navigate("/newpassword", {
          state: { token: OtpVerify.data.data.token },
        });
        // console.log("OtpVerify", OtpVerify.data.data.token);
        toast.success(OtpVerify?.data?.message);
      }
    } catch (error) {
      // console.log(error);
      toast.warning(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="main_otp_box">
        <div
          className="modal"
          style={{ display: "block", position: "initial" }}
        >
          <Modal.Dialog className="Otp" centered>
            <Modal.Header>
              <Modal.Title>Meteo Compare</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderInput={(props) => <input {...props} />}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button className="Verify-OTP" onClick={verifyOtp}>
                Verify OTP
              </Button>
            </Modal.Footer>
          </Modal.Dialog>
        </div>
      </div>
    </>
  );
};

export default Otp;
