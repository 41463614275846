import React, { useEffect, useState } from "react";
import NavBar from "Sections/HomeSections/NavBar";
import Header from "Sections/HomeSections/Header";
import WeatherRadar from "Sections/HomeSections/WeatherRadar";
import WindProfiles from "Sections/HomeSections/WindProfiles";
import ForecastingPlatform from "Sections/HomeSections/ForecastingPlatform";
import RecentlyViewed from "Sections/HomeSections/RecentlyViewed";
import Footer from "Sections/HomeSections/Footer";
import { RecentlyViewedApi } from "Apis/MainApis";
import { useSelector } from "react-redux";
import DetailRecentView from "Sections/Detailpage/DetailRecentView";
import { useDispatch } from "react-redux";

function HomePage() {
  const [wishlist, setWishlistChange] = useState(false);
  const [recentData, setRecentData] = useState([]);

  const user = useSelector((state) => state?.user?.data);
  const homeProducts = useSelector((state) => state?.homeProducts?.data);
  console.log("homeProducts", homeProducts);
  const dispatch = useDispatch();

  useEffect(() => {
    recentProduct();
  }, [wishlist]);

  const recentProduct = () => {
    const token = localStorage.getItem("Meteor_Key");
    if (token) {
      RecentlyViewedApi(token, (callbackData) => {
        setRecentData(callbackData.data);
      });
    }
  };

  return (
    <>
      <NavBar />
      <Header />
      {homeProducts?.length > 2 ? (
        <WeatherRadar homeProducts={homeProducts[2]} />
      ) : (
        ""
      )}

      {homeProducts?.length > 1 ? (
        <WindProfiles homeProducts={homeProducts[1]} />
      ) : (
        ""
      )}

      {homeProducts?.length > 0 ? (
        <ForecastingPlatform homeProducts={homeProducts[0]} />
      ) : (
        ""
      )}
      <DetailRecentView recentData={recentData} recentProduct={recentProduct} />
      <Footer />
    </>
  );
}

export default HomePage;
