import logo from "./logo.svg";
import "./App.css";
import Routes from "./Routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchWishlist } from "../src/redux/slice/fetchWishlistSlice";
import { fetchUser } from "../src/redux/slice/fetchUserSlice";
import { fetchHomeProducts } from "../src/redux/slice/fetchHomeProductsSlice";
import { setCompareLength } from "../src/redux/slice/compareLengthSlice";
import { fetchCompareProducts } from "../src/redux/slice/compareProductsSlice";

function App() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.data?.id);

  useEffect(() => {
    dispatch(fetchUser());
    dispatch(fetchHomeProducts(userId));
    dispatch(setCompareLength());
  }, [dispatch, userId]);

  if (userId) {
    dispatch(fetchWishlist());
    dispatch(fetchCompareProducts());
  }

  return (
    <div className="App">
      <Routes />
      <ToastContainer />
    </div>
  );
}

export default App;
