import React, { useEffect, useState } from "react";
import NavBar from "Sections/HomeSections/NavBar";
import Footer from "Sections/HomeSections/Footer";
import { IoMdHome } from "react-icons/io";
import { FaAngleRight } from "react-icons/fa";
import ComparisonTable from "Sections/Comparison/ComparisonTable";
import AddCard from "Sections/Comparison/AddCard";
import { featchCategoryProduct } from "Apis/MainApis";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import DetailRecentView from "Sections/Detailpage/DetailRecentView";
import { singleProductApi } from "Apis/MainApis";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCompareLength } from "../../src/redux/slice/compareLengthSlice";
import { RecentlyViewedApi } from "Apis/MainApis";
import { deleteCompareProductsApi } from "Apis/MainApis";
import { addCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../redux/slice/compareProductsSlice";
import SimpleBackdrop from "Utils/SimpleBackdrop";

const Comparison = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector((state) => state?.user?.data?.id);
  const { catId, pid1 } = useParams();
  const location = useLocation();
  const categoryId = catId?.split("_")[1];
  const categorySlug = catId?.split("_")[0];
  const firstProductId = pid1 ? pid1?.split("_")[1] : null;

  const [cardIndex, setCardIndex] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [filtersData, setFiltersData] = useState([]);
  const [popup, setPopup] = useState(false);
  const [products, setProducts] = useState([]);
  const [totalPageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [recentData, setRecentData] = useState([]);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    fetchCategory();
  }, [currentPage]);

  useEffect(() => {
    recentProduct();
    fetchProducts();
  }, []);

  const recentProduct = () => {
    const token = localStorage.getItem("Meteor_Key");
    if (token) {
      RecentlyViewedApi(token, (callbackData) => {
        setRecentData(callbackData.data);
      });
    }
  };

  const fetchProducts = async () => {
    const queryParams = new URLSearchParams(location.search);
    const updatedProducts = [];
    const url = location.search.split("&");
    let pids = [];
    for (let i = 0; i < url.length; i++) {
      if (url[i].split("=")[0][0] === "?") {
        pids.push(parseInt(url[i].split("=")[0][4]));
      } else {
        pids.push(parseInt(url[i].split("=")[0][3]));
      }
    }
    for (let i = 0; i <= pids.length; i++) {
      const pidIndex = pids[i];
      const productId = queryParams.get(`pid${pidIndex}`);
      if (productId) {
        const [_, dynamicProductId] = productId.split("_");

        await new Promise((resolve, reject) => {
          singleProductApi(
            dynamicProductId,
            userId,
            setOpen,
            false,
            (callbackData) => {
              const product = callbackData.data.product[0];
              updatedProducts.push(product);
              resolve();
            }
          );
        });
      }
    }
    setProducts(updatedProducts);
    setOpen(false);
  };

  const fetchCategory = () => {
    const data = {
      categoryId: categoryId,
      page: currentPage + 1,
      orderBy: "id",
      uid: userId || "",
      limit: 10,
    };
    const isLoader = false;
    featchCategoryProduct(data, setOpen, isLoader, (callbackData) => {
      setCategoryData(callbackData.data.categoryProducts);
      setPageCount(Math.ceil(callbackData?.data?.length / 10));
      setFiltersData(callbackData.data);
    });
  };

  const setCard = (index) => {
    setCardIndex(index);
    setPopup(true);
  };

  const handleSelect = async (newId, newSlug, productImage, productName) => {
    if (userId) {
      const data = {
        productId: newId,
        categoryId: categoryId,
        productSlug: newSlug,
        categorySlug: categorySlug,
      };
      if (cardIndex !== null) {
        if (products.some((product) => product && product.id === newId)) {
          toast.error("This product is already added");
          return;
        }
        singleProductApi(newId, userId, setOpen, false, (callbackData) => {
          const product = callbackData.data.product[0];
          const updatedProducts = [...products];
          updatedProducts.push(product);
          setProducts(updatedProducts);
          setPopup(false);
        });
        await addCompareProductsApi(data);

        const queryParams = new URLSearchParams(location.search);

        if (queryParams.get("pid1") === null) {
          queryParams.set("pid1", `${newSlug}_${newId}`);
        } else if (queryParams.get("pid2") === null) {
          queryParams.set("pid2", `${newSlug}_${newId}`);
        } else if (queryParams.get("pid3") === null) {
          queryParams.set("pid3", `${newSlug}_${newId}`);
        } else {
          queryParams.set("pid4", `${newSlug}_${newId}`);
        }

        navigate(`${location.pathname}?${queryParams.toString()}`);
        dispatch(fetchCompareProducts());
      }
    } else {
      if (cardIndex !== null) {
        if (products.some((product) => product && product.id === newId)) {
          toast.error("This product is already added");
          return;
        }
        singleProductApi(newId, userId, setOpen, false, (callbackData) => {
          const product = callbackData.data.product[0];
          const updatedProducts = [...products];
          updatedProducts.push(product);
          setProducts(updatedProducts);
          setPopup(false);
        });

        const comparisonProducts =
          JSON.parse(localStorage.getItem("comparisonProducts")) || [];

        comparisonProducts.push({
          categorySlug: categorySlug,
          categoryId: categoryId,
          productSlug: newSlug,
          productId: newId,
          productImage: productImage,
          productName: productName,
        });

        localStorage.setItem(
          "comparisonProducts",
          JSON.stringify(comparisonProducts)
        );
      }

      const queryParams = new URLSearchParams(location.search);

      if (queryParams.get("pid1") === null) {
        queryParams.set("pid1", `${newSlug}_${newId}`);
      } else if (queryParams.get("pid2") === null) {
        queryParams.set("pid2", `${newSlug}_${newId}`);
      } else if (queryParams.get("pid3") === null) {
        queryParams.set("pid3", `${newSlug}_${newId}`);
      } else {
        queryParams.set("pid4", `${newSlug}_${newId}`);
      }

      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(setCompareLength());
    }
  };

  const handleDelete = async (index, productId) => {
    if (userId) {
      const data = {
        productId: productId,
      };
      await deleteCompareProductsApi(data);

      products.splice(index, 1);
      const queryParams = new URLSearchParams(location.search);

      if (location.search.split("&")[index].split("=")[0][0] === "?") {
        queryParams.delete(
          location.search.split("&")[index].split("=")[0].split("?")[1]
        );
      } else {
        queryParams.delete(location.search.split("&")[index].split("=")[0]);
      }

      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(fetchCompareProducts());
    } else {
      const comparisonProducts =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      comparisonProducts.splice(index, 1);

      localStorage.setItem(
        "comparisonProducts",
        JSON.stringify(comparisonProducts)
      );

      // const indexToDelete = comparisonProducts.findIndex(
      //   (product) => product.productId === productId
      // );

      // if (indexToDelete !== -1) {
      //   comparisonProducts.splice(indexToDelete, 1);

      //   localStorage.setItem(
      //     "comparisonProducts",
      //     JSON.stringify(comparisonProducts)
      //   );
      // }

      const updatedProducts = [...products];
      updatedProducts.splice(index, 1);
      setProducts(updatedProducts);

      const queryParams = new URLSearchParams(location.search);

      if (location.search.split("&")[index].split("=")[0][0] === "?") {
        queryParams.delete(
          location.search.split("&")[index].split("=")[0].split("?")[1]
        );
      } else {
        queryParams.delete(location.search.split("&")[index].split("=")[0]);
      }

      navigate(`${location.pathname}?${queryParams.toString()}`);
      dispatch(setCompareLength());
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  return (
    <>
      <NavBar />
      {open ? (
        <div style={{ height: "50vh" }}>
          <SimpleBackdrop open={open} />
        </div>
      ) : (
        <>
          {" "}
          <div className="global_routing_Navbar_bottom">
            <p className="d-flex align-items-center m-0">
              <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
              <span> Categories</span>
              <FaAngleRight className="fs-6" />
              <span
                className="opacity-50"
                onClick={() =>
                  navigate(
                    `/${filtersData.categorySlug}_${filtersData.categoryId}`
                  )
                }
                style={{ cursor: "pointer" }}
              >
                {filtersData?.category_name}
              </span>
              <FaAngleRight className="fs-6" />
              <span className="opacity-50">Comparison</span>
            </p>
          </div>
          <AddCard
            products={products}
            setPopup={setPopup}
            popup={popup}
            setCard={setCard}
            categoryData={categoryData}
            handleSelect={handleSelect}
            handleDelete={handleDelete}
            totalPageCount={totalPageCount}
            handlePageChange={handlePageChange}
            currentPage={currentPage}
          />
          <ComparisonTable filtersData={filtersData} products={products} />
          <DetailRecentView
            recentData={recentData}
            recentProduct={recentProduct}
          />
        </>
      )}
      <Footer />
    </>
  );
};

export default Comparison;
