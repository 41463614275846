import React, { useState } from "react";
import "styles/Comparison/AddCard.css";
import { FaRegHeart, FaPlus } from "react-icons/fa";
import ModalPopup from "../Comparison/ModalPopup";
import { ImCross } from "react-icons/im";

const AddCard = ({
  products,
  setPopup,
  popup,
  setCard,
  categoryData,
  handleSelect,
  handleDelete,
  totalPageCount,
  handlePageChange,
  currentPage,
}) => {
  return (
    <>
      <ModalPopup
        setPopup={setPopup}
        popup={popup}
        categoryData={categoryData}
        handleSelect={handleSelect}
        totalPageCount={totalPageCount}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
      />

      <div className="AddCard">
        <div className="row">
          {[0, 1, 2, 3].map((index) => (
            <div
              className="col-xl-3 col-md-4 col-sm-6 mt-md-0 mt-4 p-4"
              key={index}
            >
              {products[index] ? (
                <>
                  <div className="AddCard-card d-flex align-items-center flex-column justify-content-start">
                    <img
                      src={products[index]?.featured_image}
                      alt="img"
                      className="comparison_image"
                    />
                    <div className="AddCard-details">
                      <div className="AddCard-details-heart">
                        <p className="m-0 text-start addcard_text">
                          {products[index]?.categoryName}
                        </p>
                        <p className="m-0 text-start addcard_text fs-6">
                          {products[index]?.name}
                        </p>
                      </div>
                      <p className="text-start addcard_text">
                        {products[index]?.description}
                      </p>
                    </div>
                    <div
                      className="cross_icon_box"
                      onClick={() => handleDelete(index, products[index].id)}
                    >
                      <ImCross className="crossIcon" />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="AddCard-card d-flex align-items-center flex-column justify-content-center">
                    <h4>{`Product ${index + 1}`}</h4>
                    <FaPlus
                      className="plus_icon"
                      onClick={() => setCard(index)}
                    />
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default AddCard;
