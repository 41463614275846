import React, { useState } from "react";
import "styles/Forms/LoginForm.css";
import logo from "Public/navBar_images/logo.png";
import { FcGoogle } from "react-icons/fc";
import { FaFacebookF } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import axios from "axios";
import { baseURl } from "Utils/BaseURL";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { fetchUser } from "../redux/slice/fetchUserSlice";

const LoginForm = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  // Email ====================
  const [email, setEmail] = useState("");

  // password ===============
  const [password, setpassword] = useState("");
  const [Showpassword, setShowPassword] = useState(false);

  //  email ---------------------
  const emailHandler = (e) => {
    setEmail(e.target.value);
  };

  // password ----------------------
  const passwordHandler = (e) => {
    setpassword(e.target.value);
  };
  const handleShowPassword = () => {
    setShowPassword(!Showpassword);
  };

  // Submit ------------------
  const submitHandler = async (e) => {
    e.preventDefault();
    if (email === "" && password === "") {
      toast.error("Please enter your email address  and password");
      return;
    }
    if (email === "") {
      toast.error("Please enter your email address ");
      return;
    }
    if (password === "") {
      toast.error("Please enter your  password");
      return;
    }

    try {
      const response = await axios.post(`${baseURl}login`, { email, password });
      setEmail("");
      setpassword("");
      if (response.data.status === "success") {
        localStorage.setItem("Meteor_Key", response.data.data.token);
        localStorage.removeItem("comparisonProducts");
        dispatch(fetchUser());
        navigate("/");
        toast.success(response?.data?.message);
      } else {
        toast.success(response?.data?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  return (
    <>
      <div className="container-fluid">
        <div className="row LoginForm d-flex justify-content-center align-items-center">
          <div className="col-md-4 d-flex  align-items-center">
            <div className="login_form">
              <div className="card">
                <div className="d-flex justify-content-center align-items-center pb-3">
                  <div className="d-flex justify-content-center align-items-center  avtar ">
                    <img
                      src={logo}
                      alt="img"
                      className="w-100 "
                      onClick={() => navigate("/")}
                    />
                  </div>
                </div>
                <h6>Welcome to Meteo Compare</h6>

                <form onSubmit={submitHandler}>
                  <label for="email">Email address*</label>
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={emailHandler}
                  />

                  <label for="password">Password*</label>
                  <div className="pass_visible">
                    <input
                      type={Showpassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      value={password}
                      onChange={passwordHandler}
                    />
                    <div
                      className="password_toggle"
                      onClick={handleShowPassword}
                    >
                      {Showpassword ? (
                        <FaEye className="eyes" />
                      ) : (
                        <FaEyeSlash className="eyes" />
                      )}
                    </div>
                  </div>
                  <button type="submit" className="login_btn">
                    Login
                  </button>
                  <p className="my-md-2">
                    Don’t have an account?{" "}
                    <span>
                      <Link to="/signup">Sign Up</Link>
                    </span>
                  </p>
                </form>
                <div className="switch">
                  <Link to="/forgot">Forget password ?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
