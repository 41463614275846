import React, { useEffect, useState } from "react";
import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import newsfilterimg from "Public/filterProduct_img/filterproduct_image.png";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import "styles/NewsDetail.css";
import newdetailsimg from "Public/images/AboutUsHeaderImage1.png";
import { useParams } from "react-router-dom";
import { fetchSingleBlogApi } from "Apis/MainApis";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";

const NewsDetails = () => {
  const navigate = useNavigate();
  const { productSlug } = useParams();
  const postId = productSlug.split("_")[1];
  const [product, setProduct] = useState({});
  const [recentPost, setRecentPost] = useState([]);

  useEffect(() => {
    async function fetchPost() {
      const data = {
        postId: postId,
      };
      const response = await fetchSingleBlogApi(data);
      // console.log(response?.data?.data?.product[0]);
      setProduct(response?.data?.data?.product[0]);
      setRecentPost(response?.data?.data?.recentPosts);
    }
    fetchPost();
  }, []);

  const handleSingleBlog = (postSlug, postId) => {
    navigate(`/news-detail/${postSlug}_${postId}`);
  };

  return (
    <>
      <NavBar />
      <div className="global_routing_Navbar_bottom">
        <p className="d-flex align-items-center m-0">
          <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
          <span style={{ cursor: "pointer" }} onClick={() => navigate('/news')}>
            News
          </span>
          <FaAngleRight className="fs-6" />
          <span className="opacity-50" style={{ cursor: "pointer" }}>
            {product?.post_title}
          </span>
        </p>
      </div>
      <div className="NewsDetails">
        <div className="row ">
          <div className="col-lg-9 col-md-8 col-12">
            <div className="newsDetailsleftSec text-start ">
              <div className="imagesec">
                <img
                  src={product?.images}
                  alt="img"
                  className="newsproductImg"
                />
              </div>
              <div className="newsDetailsleftSec_content  py-2">
                <p>{product?.description}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-0">
            <div className="desktopRecentPost">
              <div className="recentPost">
                <div className="news_Category">
                  <div className="category-title">
                    <h5 className="fw-bold">Recent Post</h5>
                    <div class="progresbar">
                      <div class="progres_line"></div>
                    </div>
                  </div>
                  <div className="NewsFilters">
                    {recentPost?.map((item, i) => (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center recent_viewSec flex-wrap"
                          key={i}
                          onClick={() =>
                            handleSingleBlog(item?.post_slug, item?.id)
                          }
                        >
                          <div className="leftSec">
                            <img src={item?.post_image} alt="img" />
                          </div>
                          <div className="right_Sec">
                            <h6 className="fw-bold">{item?.post_title}</h6>
                            <div className="d-flex flex-wrap align-items-center justify-content-between details gap-1">
                              <div className="d-flex align-items-center gap-1 iconsSec flex-wrap">
                                <FaRegCalendarAlt className="i" />
                                <span>{item?.date}</span>
                              </div>
                              {/* <div className="d-flex align-items-center gap-1 iconsSec flex-wrap">
                          <IoEyeSharp className="i" />
                          <span>290 Views</span>
                        </div> */}
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mobileRecentPost"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default NewsDetails;
