import React from "react";
import "styles/NewsSidebar.css";
import newsfilterimg from "Public/filterProduct_img/filterproduct_image.png";
import { FaRegCalendarAlt } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { BiMenuAltLeft } from "react-icons/bi";

const NewsSidebar = ({ categoriesBlog, categoryId, recentPost }) => {
  const navigate = useNavigate();

  const handleSingleBlog = (postSlug, postId) => {
    navigate(`/news-detail/${postSlug}_${postId}`);
  };
  return (
    <>
      <div className="desktop_news_sidebar">
        <div className="news_sidebar  mt-4">
          <div className="news_Category">
            <div className="category-title">
              <h5 className="fw-bold">Category</h5>
              <div class="progresbar">
                <div class="progres_line"></div>
              </div>
            </div>
            <div className="NewsFilters">
              <p
                className="m-0"
                style={{
                  cursor: "pointer",
                  color: categoryId == "" ? "#134a80" : "black",
                }}
                onClick={() => navigate(`/news`)}
              >
                All News
              </p>
              <hr />
              {categoriesBlog?.map((category, i) => (
                <>
                  <p
                    className="m-0"
                    style={{
                      cursor: "pointer",
                      color: categoryId == category?.id ? "#134a80" : "black",
                    }}
                    onClick={() =>
                      navigate(`/news/${category?.slug}_${category?.id}`)
                    }
                  >
                    {category?.name}
                  </p>
                  <hr />
                </>
              ))}
            </div>
          </div>

          <div className="news_Category mt-3">
            <div className="category-title">
              <h5 className="fw-bold">Recent Post</h5>
              <div class="progresbar">
                <div class="progres_line"></div>
              </div>
            </div>
            <div className="NewsFilters">
              {recentPost?.map((item, i) => (
                <>
                  <div
                    className="d-flex justify-content-between align-items-center recent_viewSec flex-wrap"
                    key={i}
                    onClick={() => handleSingleBlog(item?.post_slug, item?.id)}
                  >
                    <div className="leftSec">
                      <img src={item?.post_image} alt="img" />
                    </div>
                    <div className="right_Sec">
                      <h6 className="fw-bold">{item?.post_title}</h6>
                      <div className="d-flex flex-wrap align-items-center justify-content-between details gap-1">
                        <div className="d-flex align-items-center gap-1 iconsSec flex-wrap">
                          <FaRegCalendarAlt className="i" />
                          <span>{item?.date}</span>
                        </div>
                        {/* <div className="d-flex align-items-center gap-1 iconsSec flex-wrap">
                              <IoEyeSharp className="i" />
                              <span>290 Views</span>
                            </div> */}
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Mobile menuabar--------------------------------- */}
      <div className="newwMobileBar">
        <div class="offcanvas offcanvas-start text-bg-white" id="NewsMobileBar">
          <div class="offcanvas-header">
            <div className="news_sidebar  mt-2">
              <div className="d-flex justify-content-end mb-3">
                <button
                  type="button"
                  class="btn-close btn-close-dark fw-bold"
                  data-bs-dismiss="offcanvas"
                ></button>
              </div>
              <div className="news_Category">
                <div className="category-title">
                  <h5 className="fw-bold">Category</h5>
                  <div class="progresbar">
                    <div class="progres_line"></div>
                  </div>
                </div>
                <div className="NewsFilters">
                  {categoriesBlog?.map((category, i) => (
                    <>
                      <p
                        className="m-0"
                        style={{
                          cursor: "pointer",
                          color:
                            categoryId == category?.id ? "#134a80" : "black",
                        }}
                        onClick={() =>
                          navigate(`/news/${category?.slug}_${category?.id}`)
                        }
                      >
                        {category?.name}
                      </p>
                      <hr />
                    </>
                  ))}
                </div>
              </div>

              <div className="news_Category mt-3">
                <div className="category-title">
                  <h5 className="fw-bold">Recent Post</h5>
                  <div class="progresbar">
                    <div class="progres_line"></div>
                  </div>
                </div>
                <div className="NewsFilters">
                  {recentPost?.map((item, i) => (
                    <>
                      <div
                        className="d-flex justify-content-between align-items-center recent_viewSec flex-wrap "
                        onClick={() =>
                          handleSingleBlog(item?.post_slug, item?.id)
                        }
                      >
                        <div className="leftSec">
                          <img src={item?.post_image} alt="img" />
                        </div>
                        <div className="right_Sec">
                          <h6 className="fw-bold">{item?.post_title}</h6>
                          <div className="d-flex flex-wrap align-items-center justify-content-between details gap-1">
                            <div className="d-flex align-items-center gap-1 iconsSec flex-wrap">
                              <FaRegCalendarAlt className="i" />
                              <span>{item?.date}</span>
                            </div>
                            {/* <div className="d-flex align-items-center gap-1 iconsSec flex-wrap">
                  <IoEyeSharp className="i" />
                  <span>290 Views</span>
                </div> */}
                          </div>
                        </div>
                      </div>
                    </>
                  ))}

                  <hr />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* newsmobile Toggle------------------------------ */}
        <BiMenuAltLeft
          className="fs-2 d-flex justify-content-start"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#NewsMobileBar"
        />
      </div>
    </>
  );
};

export default NewsSidebar;
