import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import React from "react";
import "../styles/Profile/Main_ProfilePge.css";
import ProfileSideBar from "Sections/Profile/ProfileSideBar";
import ProfileRightBar from "Sections/Profile/ProfileRightBar";

const Profile = () => {
  return (
    <>
      <NavBar />
      <div className="Main_ProfilePge">
        <div className="row">
          <div className="col-md-4">
            <ProfileSideBar />
          </div>
          <div className="col-md-8 mt-md-0 my-2 p-sm-0 p-0">
            <ProfileRightBar />
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Profile;
