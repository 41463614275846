import React, { useState } from "react";
import NavBar from "Sections/HomeSections/NavBar";
import Footer from "Sections/HomeSections/Footer";
import "../styles/Wishlist.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RiArrowLeftRightFill } from "react-icons/ri";
import Button from "react-bootstrap/Button";
import {
  fetchWishlist,
  removeFromWishlist,
} from "../redux/slice/fetchWishlistSlice";
import { MdHeight } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCompareLength } from "../redux/slice/compareLengthSlice";
import { addCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../redux/slice/compareProductsSlice";
import OffCanvasExample from "Utils/BottomComparison";

const Wishlist = () => {
  const wishlist = useSelector((state) => state?.wishlist?.data);
  const [changeOffcanvas, setChangeOffCnavas] = useState(false);
  const user = useSelector((state) => state?.user?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRemoveWishlist = async (productId) => {
    await dispatch(removeFromWishlist(productId));
    await dispatch(fetchWishlist());
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow(true);

  const handleCompare = async (item) => {
    if (user?.id) {
      const data = {
        productId: item.productId,
        categoryId: item.category_id,
        productSlug: item.slug,
        categorySlug: item.category_slug,
      };
      const response = await addCompareProductsApi(data);
      if (response.status === 200) {
        toast(response?.data?.message);
        toggleShow();
      }
      dispatch(fetchCompareProducts());
    } else {
      let compareData =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      if (
        compareData.length > 0 &&
        compareData[0].categoryId != item.category_id
      ) {
        toast.warning("You can only compare products from the same category.");
        return;
      }

      if (compareData.length >= 4) {
        toast.warning("You can only compare a maximum of four products.");
        return;
      }

      const existingProduct = compareData.find(
        (product) => product.productId == item.productId
      );

      if (existingProduct) {
        toast("This product is already added to comparison.");
        toggleShow();
        return;
      }

      compareData.push({
        productId: item.productId,
        categoryId: item.category_id,
        categorySlug: item.category_slug,
        productSlug: item.slug,
        productImage: item.featured_image,
        productName: item?.name,
      });

      toast("Product Added");

      localStorage.setItem("comparisonProducts", JSON.stringify(compareData));
      dispatch(setCompareLength());
      toggleShow();
    }
  };

  return (
    <>
      <OffCanvasExample
        show={show}
        toggleShow={toggleShow}
        handleClose={handleClose}
        changeOffcanvas={changeOffcanvas}
        setChangeOffCnavas={setChangeOffCnavas}
      />
      <NavBar />
      <div className="py-5" style={{ minHeight: "50vh" }}>
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center">
            {wishlist === undefined || wishlist?.length === 0 ? (
              <>
                <img
                  src="emptywishlist.png"
                  alt="wishlist_empty"
                  style={{ width: 250, height: 200 }}
                ></img>
                <p>Your wishlist is empty</p>
              </>
            ) : (
              <>
                {wishlist?.map((item, i) => (
                  <div className="col-lg-8 col-11 wishlist_card mb-3 py-3">
                    <div className="row">
                      <div
                        className="col-md-4 col-sm-4 col-12 d-flex align-items-center justify-content-start"
                        onClick={() => {
                          navigate(
                            `/detail-page/${item?.slug}_${item?.productId}`,
                            window.scrollTo(0, 0)
                          );
                        }}
                      >
                        <img
                          src={item?.featured_image}
                          alt="wishlist_image"
                          className="wishlist_image"
                        ></img>
                      </div>
                      <div className="col-md-8 col-sm-8  col-12 text-start">
                        <p className="wishlist_text wishlist_name m-0 m-1">
                          {item?.category_name}
                        </p>
                        <p className="wishlist_text wishlist_name m-0 m-1">
                          {item?.name}
                        </p>
                        <p className="wishlist_text wishlist_description m-0 m-1">
                          {item?.description}
                        </p>
                        <div className="d-flex align-itmes-center">
                          <Button
                            className="global_button_colored gap-3 me-2"
                            onClick={() => handleCompare(item)}
                          >
                            <RiArrowLeftRightFill /> Compare
                          </Button>

                          <Button
                            className="global_button_colored gap-3"
                            onClick={() =>
                              handleRemoveWishlist(item?.productId)
                            }
                          >
                            Remove
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Wishlist;
