import React, { useEffect, useState } from "react";
import NavBar from "Sections/HomeSections/NavBar";
import Footer from "Sections/HomeSections/Footer";
import FilterSideBar from "Sections/CategoriesSections/FilterSideBar";
import FilterProduct from "Sections/CategoriesSections/FilterProduct";
import { FaAngleRight } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";
import "styles/CategoriesNavBar/CategoriesNavBar.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import EditProduct from "Sections/CategoriesSections/EditProduct";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { fetchProductAp3i } from "Apis/MainApis";
import FilterProductVertical from "Sections/CategoriesSections/FilterProductVertical";
import { PiGridFourFill } from "react-icons/pi";
import { HiOutlineBars2 } from "react-icons/hi2";
import { useSelector } from "react-redux";
import {
  addToWishlist,
  fetchWishlist,
  removeFromWishlist,
} from "../redux/slice/fetchWishlistSlice.js";
import { useDispatch } from "react-redux";
import LoginModal from "../Authorization/LoginModal.js";
import { setCompareLength } from "../redux/slice/compareLengthSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../redux/slice/compareProductsSlice";
import OffCanvasExample from "Utils/BottomComparison";
import SimpleBackdrop from "Utils/SimpleBackdrop.js";

const CategoriesNavBar = () => {
  const [section, setSection] = useState("horizontal");
  const [featchProduct, setFeatchProduct] = useState([]);
  const [filters, setFilters] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [totalPageCount, setPageCount] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [wishlist, setWishlistChange] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [changeOffcanvas, setChangeOffCnavas] = useState(false);
  const [open, setOpen] = useState(true);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const categoryId = slug?.split("_")[1];
  const location = useLocation();

  const userId = useSelector((state) => state?.user?.data?.id);

  useEffect(() => {
    const queryString = location.pathname.split("/")[2];
    const queryParams = new URLSearchParams(queryString);
    const keyValues = {};
    for (const [key, value] of queryParams.entries()) {
      keyValues[key] = value.split("|");
    }
    setSelectedFilters(keyValues);
  }, [location.pathname]);

  useEffect(() => {
    CategoriesProduct();
  }, [categoryId, location.search, selectedFilters, wishlist, currentPage]);

  const CategoriesProduct = () => {
    let data = JSON.stringify({
      categoryId: categoryId,
      page: currentPage + 1,
      orderBy: "id",
      uid: userId || "",
      ...selectedFilters,
      limit: 10,
    });
    const isLoader = currentPage + 1 > 1 ? false : true;
    fetchProductAp3i(data, setOpen, isLoader, (callbackData) => {
      setFeatchProduct(callbackData.data);
      setPageCount(Math.ceil(callbackData?.data?.length / 10));
      setFilters(callbackData.data.filters);
    });
  };

  const handleCheckboxFilter = (filterColumn, filterValue, isChecked) => {
    setCurrentPage(0);
    const updatedFilters = { ...selectedFilters };
    if (isChecked) {
      updatedFilters[filterColumn] = [
        ...(updatedFilters[filterColumn] || []),
        filterValue,
      ];
    } else {
      updatedFilters[filterColumn] = (
        updatedFilters[filterColumn] || []
      ).filter((value) => value !== filterValue);

      if (updatedFilters[filterColumn].length === 0) {
        delete updatedFilters[filterColumn];
      }
    }

    const hasFilters = Object.keys(updatedFilters).length > 0;

    let queryParams = new URLSearchParams();

    Object.entries(updatedFilters).forEach(([key, values]) => {
      queryParams.append(key, values.join("|"));
    });

    const queryString = queryParams.toString();

    if (hasFilters) {
      navigate(
        `/${featchProduct.categorySlug}_${featchProduct.categoryId}/${queryString}`
      );
    } else {
      navigate(`/${featchProduct.categorySlug}_${featchProduct.categoryId}`);
    }
  };

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleWishlist = async (id, wish) => {
    if (userId) {
      if (wish === "yes") {
        await dispatch(removeFromWishlist(id));
      } else {
        await dispatch(addToWishlist(id));
      }
      await dispatch(fetchWishlist());
      setWishlistChange(!wishlist);
    } else {
      setShowModal(true);
    }
  };

  const handleCompare = async (item) => {
    if (userId) {
      const data = {
        productId: item.id,
        categoryId: featchProduct.categoryId,
        productSlug: item.slug,
        categorySlug: featchProduct.categorySlug,
      };
      const response = await addCompareProductsApi(data);
      if (response.status === 200) {
        toast(response?.data?.message);
        toggleShow();
      }
      dispatch(fetchCompareProducts());
    } else {
      let compareData =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      if (
        compareData.length > 0 &&
        compareData[0].categoryId != featchProduct.categoryId
      ) {
        toast.warning("You can only compare products from the same category.");
        return;
      }

      if (compareData.length >= 4) {
        toast.warning("You can only compare a maximum of four products.");
        return;
      }

      const existingProduct = compareData.find(
        (product) => product.productId == item.id
      );

      if (existingProduct) {
        toast("This product is already added to comparison.");
        toggleShow();
        return;
      }

      compareData.push({
        productId: item.id,
        categoryId: featchProduct.categoryId,
        categorySlug: featchProduct.categorySlug,
        productSlug: item.slug,
        productImage: item.featured_image,
        productName: item?.name,
      });

      toast("Product Added");
      localStorage.setItem("comparisonProducts", JSON.stringify(compareData));
      dispatch(setCompareLength());
      toggleShow();
    }
    setChangeOffCnavas(!changeOffcanvas);
  };

  return (
    <>
      <OffCanvasExample
        show={show}
        toggleShow={toggleShow}
        handleClose={handleClose}
        changeOffcanvas={changeOffcanvas}
        setChangeOffCnavas={setChangeOffCnavas}
      />
      <LoginModal showModal={showModal} setShowModal={setShowModal} />
      <NavBar />
      {open ? (
        <div style={{ height: "50vh" }}>
          <SimpleBackdrop open={open} />
        </div>
      ) : (
        <div
          className="row m-0 global_routing_Navbar_bottom"
          style={{ padding: "2% 5% 2% 5%" }}
        >
          <p className="d-flex align-items-center">
            <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
            <span> Categories</span>
            <FaAngleRight className="fs-6" />
            <span className="opacity-50">{featchProduct?.category_name}</span>
          </p>
          <div className="d-flex justify-content-between pt-4 flex-wrap ">
            <h4 className="fw-bold plateForm">
              {featchProduct?.category_name}
            </h4>
            <div className="d-flex align-items-center gap-2 mb-3">
              <PiGridFourFill
                className="fs-3 filterBar"
                onClick={() => setSection("horizontal")}
              />
              <HiOutlineBars2
                className="fs-2 filterBar"
                onClick={() => setSection("")}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-0">
            <FilterSideBar
              filters={filters}
              handleCheckboxFilter={handleCheckboxFilter}
              selectedFilters={selectedFilters}
            />
          </div>
          <div className="col-lg-9 col-md-8 col-sm-12 ">
            {section === "horizontal" ? (
              <FilterProduct
                featchProduct={featchProduct}
                totalPageCount={totalPageCount}
                handlePageChange={handlePageChange}
                handleWishlist={handleWishlist}
                handleCompare={handleCompare}
              />
            ) : (
              <FilterProductVertical
                featchProduct={featchProduct}
                handleWishlist={handleWishlist}
                totalPageCount={totalPageCount}
                handlePageChange={handlePageChange}
                handleCompare={handleCompare}
              />
            )}
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default CategoriesNavBar;
