import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaRegHeart } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import "styles/WindProfiles.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  fetchWishlist,
  removeFromWishlist,
} from "../../redux/slice/fetchWishlistSlice";
import { FaHeart } from "react-icons/fa6";
import { fetchHomeProducts } from "../../redux/slice/fetchHomeProductsSlice";
import LoginModal from "../../Authorization/LoginModal";
import { setCompareLength } from "../../redux/slice/compareLengthSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../../redux/slice/compareProductsSlice";
import OffCanvasExample from "Utils/BottomComparison";

const WindProfiles = ({ homeProducts }) => {
  const [showModal, setShowModal] = useState(false);
  const [changeOffcanvas, setChangeOffCnavas] = useState(false);
  const user = useSelector((state) => state?.user?.data);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.1,
        },
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <FaArrowRight className="WindProfiles_Arrowr_right" onClick={onClick} />
    );
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <FaArrowLeft className="WindProfiles_Arrowr_left" onClick={onClick} />
    );
  };

  const handleWishlist = async (id, wish) => {
    if (user?.id) {
      if (wish === "yes") {
        await dispatch(removeFromWishlist(id));
      } else {
        await dispatch(addToWishlist(id));
      }
      await dispatch(fetchWishlist());
      dispatch(fetchHomeProducts(user?.id));
    } else {
      setShowModal(true);
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow(true);

  const handleCompare = async (item) => {
    if (user?.id) {
      const data = {
        productId: item.id,
        categoryId: homeProducts.id,
        productSlug: item.slug,
        categorySlug: homeProducts.categorySlug,
      };
      const response = await addCompareProductsApi(data);
      if (response.status === 200) {
        toast(response?.data?.message);
        toggleShow();
      }
      dispatch(fetchCompareProducts());
    } else {
      let compareData =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      if (
        compareData.length > 0 &&
        compareData[0].categoryId != homeProducts.id
      ) {
        toast.warning("You can only compare products from the same category.");
        return;
      }

      if (compareData.length >= 4) {
        toast.warning("You can only compare a maximum of four products.");
        return;
      }

      const existingProduct = compareData.find(
        (product) => product.productId == item.id
      );

      if (existingProduct) {
        toast("This product is already added to comparison.");
        toggleShow();
        return;
      }

      compareData.push({
        productId: item.id,
        categoryId: homeProducts.id,
        categorySlug: homeProducts.categorySlug,
        productSlug: item.slug,
        productImage: item.featured_image,
        productName: item?.name,
      });

      toast("Product Added");

      localStorage.setItem("comparisonProducts", JSON.stringify(compareData));
      dispatch(setCompareLength());
      toggleShow();
    }
    setChangeOffCnavas(!changeOffcanvas);
  };

  return (
    <>
      <OffCanvasExample
        show={show}
        toggleShow={toggleShow}
        handleClose={handleClose}
        changeOffcanvas={changeOffcanvas}
        setChangeOffCnavas={setChangeOffCnavas}
      />
      <LoginModal showModal={showModal} setShowModal={setShowModal} />
      <div className="WindProfiles">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="col-xl-5  col-lg-5  col-md-6 col-sm-6  col-8  d-flex ">
            <h3 className="m-0 px-3 WindProfiles-heading  fw-bold text-start">
              {homeProducts?.categoryName}
            </h3>
          </div>

          <div className="col-xl-6  col-lg-6 col-md-4 col-sm-6 col-4 d-flex align-items-center justify-content-end ">
            <Button
              className="global_button_white"
              id="WindProfiles_viewAll"
              onClick={() =>
                navigate(`${homeProducts?.categorySlug}_${homeProducts?.id}`)
              }
              title={homeProducts?.categoryName}
            >
              View All
            </Button>
          </div>
          <div className="col-xl-1  col-lg-1   col-md-1 ">
            <div className="position-relative"></div>
          </div>
        </div>
        <div className="row py-3">
          {homeProducts?.products?.length <= 1 ? (
            <>
              {homeProducts?.products?.map((item, i) => {
                return (
                  <div className=" py-3 px-4" key={i}>
                    <div className="WindProfiles-card ">
                      <img
                        src={item?.featured_image}
                        class="card-img-top windprofile_image"
                        alt="..."
                        onClick={() => {
                          navigate(
                            `detail-page/${item?.slug}_${item?.id}`,
                            window.scrollTo(0, 0)
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <div className="WindProfiles-details">
                        <div className="d-flex justify-content-between align-items-center WindProfiles-details-heart">
                          <p className="m-0 windprofile_cardtext text-start">
                            {item?.name}
                          </p>
                          {item.wishlist === "no" ? (
                            <>
                              <FaRegHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <FaHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          )}
                        </div>
                        <p className="text-start windprofile_cardtext">
                          {item?.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            className="global_button_white"
                            onClick={() => {
                              navigate(
                                `detail-page/${item?.slug}_${item?.id}`,
                                window.scrollTo(0, 0)
                              );
                            }}
                          >
                            Details
                          </Button>
                          <Button
                            className="global_button_colored"
                            onClick={() => handleCompare(item)}
                          >
                            Compare
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <Slider
              {...settings}
              nextArrow={<CustomNextArrow />}
              prevArrow={<CustomPrevArrow />}
            >
              {homeProducts?.products?.map((item, i) => {
                return (
                  <div className=" py-3 px-4" key={i}>
                    <div className="WindProfiles-card ">
                      <img
                        src={item?.featured_image}
                        class="card-img-top windprofile_image"
                        alt="..."
                        onClick={() => {
                          navigate(
                            `detail-page/${item?.slug}_${item?.id}`,
                            window.scrollTo(0, 0)
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <div className="WindProfiles-details">
                        <div className="d-flex justify-content-between align-items-center WindProfiles-details-heart">
                          <p className="m-0 windprofile_cardtext text-start">
                            {item?.name}
                          </p>
                          {item.wishlist === "no" ? (
                            <>
                              <FaRegHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <FaHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          )}
                        </div>
                        <p className="text-start windprofile_cardtext">
                          {item?.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            className="global_button_white"
                            onClick={() => {
                              navigate(
                                `detail-page/${item?.slug}_${item?.id}`,
                                window.scrollTo(0, 0)
                              );
                            }}
                          >
                            Details
                          </Button>
                          <Button
                            className="global_button_colored"
                            onClick={() => handleCompare(item)}
                          >
                            Compare
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          )}
        </div>
      </div>
    </>
  );
};

export default WindProfiles;
