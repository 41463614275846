import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import HomePage from "Pages/HomePage";
import CategoriesNavBar from "Pages/CategoriesNavBar";
import DetailPage from "Pages/DetailPage";
import Comparison from "Pages/Comparison";
import LoginForm from "Authorization/LoginForm";
import Signup from "Authorization/Signup";
import ForgotPassword from "Authorization/ForgotPassword";
import Otp from "Authorization/Otp";
import NewPassword from "Authorization/NewPassword";
import SupplierPortfolio from "Sections/OtherPages/SupplierPortfolio";
import IndustryNews from "Sections/OtherPages/IndustryNews";
import AboutUs from "Sections/OtherPages/AboutUs";
import ContactUs from "Sections/OtherPages/ContactUs";
import Terms from "Sections/OtherPages/Terms";
import PrivacyPolicy from "Sections/OtherPages/PrivacyPolicy";
import News from "Sections/OtherPages/News";
import Videos from "Sections/OtherPages/Videos";
import Wishlist from "Pages/Wishlist";
import Profile from "Pages/Profile";
import NewsDetails from "Sections/OtherPages/NewsDetail";
import PortfolioDetails from "Sections/OtherPages/PortfolioDetails";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "detail-page/:pid",
    element: <DetailPage />,
  },
  {
    path: "/compare/:catId/*",
    element: <Comparison />,
    children: [
      {
        path: ":pid1/*",
        element: <Comparison />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginForm />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/forgot",
    element: <ForgotPassword />,
  },
  {
    path: "/otp",
    element: <Otp />,
  },
  {
    path: "/newpassword",
    element: <NewPassword />,
  },
  {
    path: "/portfolio",
    element: <SupplierPortfolio />,
    children: [
      {
        path: ":categorySlug",
        element: <SupplierPortfolio />,
      },
    ],
  },
  {
    path: "/industrynews",
    element: <IndustryNews />,
  },
  {
    path: "/aboutus",
    element: <AboutUs />,
  },
  {
    path: "/contactus",
    element: <ContactUs />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacypolicy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/news",
    element: <News />,
    children: [
      {
        path: ":categorySlug",
        element: <News />,
      },
    ],
  },
  {
    path: "/videos",
    element: <Videos />,
    children: [
      {
        path: ":categorySlug",
        element: <Videos />,
      },
    ],
  },
  {
    path: "/wishlist",
    element: <Wishlist />,
  },
  {
    path: "/profile",
    element: <Profile />,
  },
  {
    path: "/news-detail/:productSlug",
    element: <NewsDetails />,
  },
  {
    path: "/portfolio-details/:portfolioSlug",
    element: <PortfolioDetails />,
  },
  {
    path: "/:slug/*",
    element: <CategoriesNavBar />,
  },
]);

function Routes() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default Routes;
