import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import NavBar from "Sections/HomeSections/NavBar";
import Footer from "Sections/HomeSections/Footer";
import { fetchSinglePortfolio } from "Apis/MainApis";
import { FaAngleRight } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";
import "../../styles/PortFolio.css";
import { useNavigate } from "react-router-dom";

function PortfolioDetails() {
  const navigate = useNavigate();
  const { portfolioSlug } = useParams();
  const portfolioId = portfolioSlug.split("_")[1];
  const [product, setProduct] = useState({});

  useEffect(() => {
    async function fetchPost() {
      const data = {
        portfolioId: portfolioId,
      };
      const response = await fetchSinglePortfolio(data);
      setProduct(response?.data?.data?.supplierData[0]);
    }
    fetchPost();
  }, [portfolioId]);

  return (
    <>
      <NavBar />
      <div className="global_routing_Navbar_bottom">
        <p className="d-flex align-items-center m-0">
          <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
          <span
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/portfolio")}
          >
            Supplier Portfolio
          </span>
          <FaAngleRight className="fs-6" />
          <span className="opacity-50" style={{ cursor: "pointer" }}>
            {product?.supplier_name}
          </span>
        </p>
      </div>
      <div className="PortfolioDetails">
        <div className="row ">
          <div className="col-lg-12 col-md-8 col-12">
            <div className="newsDetailsleftSec text-start ">
              <div className="imagesec">
                <img
                  src={product?.images}
                  alt="img"
                  className="newsproductImg"
                />
              </div>
              <div className="newsDetailsleftSec_content  py-2">
                <p>{product?.description}</p>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-3 col-md-4 col-0">
            <div className="desktopRecentPost">
              <div className="recentPost">
                <div className="news_Category">
                  <div className="category-title">
                    <h5 className="fw-bold">Recent Post</h5>
                    <div class="progresbar">
                      <div class="progres_line"></div>
                    </div>
                  </div>
                  <div className="NewsFilters">
                    {recentPost?.map((item, i) => (
                      <>
                        <div
                          className="d-flex justify-content-between align-items-center recent_viewSec flex-wrap"
                          key={i}
                          onClick={() =>
                            handleSingleBlog(item?.post_slug, item?.id)
                          }
                        >
                          <div className="leftSec">
                            <img src={item?.post_image} alt="img" />
                          </div>
                          <div className="right_Sec">
                            <h6 className="fw-bold">{item?.post_title}</h6>
                            <div className="d-flex flex-wrap align-items-center justify-content-between details gap-1">
                              <div className="d-flex align-items-center gap-1 iconsSec flex-wrap">
                                <FaRegCalendarAlt className="i" />
                                <span>{item?.date}</span>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mobileRecentPost"></div>
          </div> */}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default PortfolioDetails;
