import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchUserApi } from "Apis/AuthApis";

export const fetchUser = createAsyncThunk("fetchUser", async () => {
  try {
    const response = await fetchUserApi();
    return response?.data?.data?.user;
  } catch (error) {
    console.log("Error fetching user:", error.response);
    return null;
  }
});

const fetchUserSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchUser.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export default fetchUserSlice.reducer;
