import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import "../../styles/Profile/ChangePassword.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { changePasswordApi } from "Apis/MainApis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ChangePasswordModal({ showResetPassword, setShowResetPassword }) {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validationErrors, setValidationErrors] = useState({});
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showconfirmPassword, setshowconfirmPassword] = useState(false);

  const resetChangePasswordForm = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");
    setValidationErrors({});
  };

  const validateNewPassword = () => {
    const errors = {};
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!passwordRegex.test(newPassword)) {
      errors.newpassword =
        "password must be at least 8 characters and include at least one lowercase letter one uppercase letter one digit and one special character";
    }
    if (newPassword !== confirmPassword) {
      errors.confirmpassword = "password do not match";
    }
    if (!newPassword) {
      errors.newpassword = "new password cannot be empty";
    }

    if (!confirmPassword) {
      errors.confirmpassword = "confirm password cannot be empty";
    }
    if (!oldPassword) {
      errors.oldpassword = "old password cannot be empty";
    }
    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
    if (validationErrors.newpassword) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, newpassword: "" }));
    }
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    if (validationErrors.confirmpassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        confirmpassword: "",
      }));
    }
  };

  const handleOldPasswordChange = (e) => {
    setOldPassword(e.target.value);
    if (validationErrors.oldpassword) {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        oldpassword: "",
      }));
    }
  };

  const handleSave = async () => {
    if (validateNewPassword()) {
      const data = {
        oldPassword: oldPassword,
        newPassword: newPassword,
        confirmnewPassword: confirmPassword,
      };

      const response = await changePasswordApi(data);
      console.log(response.status);
      if (response.status === 200) {
        toast.success(response?.data?.message);
        resetChangePasswordForm();
        setShowResetPassword(false);
        localStorage.clear();
        navigate("/login");
      }
    }
  };
  return (
    <Modal
      show={showResetPassword}
      onHide={() => setShowResetPassword(false)}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="resetpassword_title">
          Change Password
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="oldPassword">
            <Form.Label className="resetpassword-label m-0 pb-2">
              Old Password
            </Form.Label>
            <div className="resetpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.oldpassword ? "1px solid red" : "",
                  color: validationErrors.oldpassword ? "red" : "",
                }}
                type="text"
                placeholder="old password"
                value={oldPassword}
                className="newpassword-formcontrol"
                onChange={handleOldPasswordChange}
              />
            </div>
            {validationErrors.oldpassword && (
              <div className="oldpassword_error">
                {validationErrors.oldpassword}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="resetPassword">
            <Form.Label className="resetpassword-label m-0 py-2">
              New Password
            </Form.Label>
            <div className="resetpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.newpassword ? "1px solid red" : "",
                  color: validationErrors.newpassword ? "red" : "",
                }}
                type={showNewPassword ? "text" : "password"}
                placeholder="new password"
                value={newPassword}
                className="newpassword-formcontrol"
                onChange={handleNewPasswordChange}
              />
              <div
                className="newpassword_toggle"
                onClick={() => setNewShowPassword(!showNewPassword)}
              >
                {showNewPassword ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
            {validationErrors.newpassword && (
              <div className="newpassword_error">
                {validationErrors.newpassword}
              </div>
            )}
          </Form.Group>

          <Form.Group controlId="resetconfirmPassword">
            <Form.Label className="resetpassword-label m-0 py-2">
              Confirm New Password
            </Form.Label>
            <div className="resetpassword_confirmpassword_input">
              <Form.Control
                style={{
                  border: validationErrors.confirmpassword
                    ? "1px solid red"
                    : "",
                  color: validationErrors.confirmpassword ? "red" : "",
                }}
                type={showconfirmPassword ? "text" : "password"}
                placeholder="confirm password"
                value={confirmPassword}
                className="newpassword-formcontrol"
                onChange={handleConfirmPasswordChange}
              />
              <div
                className="resetpassword_confirmpassword_toggle"
                onClick={() => setshowconfirmPassword(!showconfirmPassword)}
              >
                {showconfirmPassword ? <FaEye /> : <FaEyeSlash />}
              </div>
            </div>
            {validationErrors.confirmpassword && (
              <div className="confirmpassword_error">
                {validationErrors.confirmpassword}
              </div>
            )}
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          className="resetpassword_button"
          onClick={handleSave}
        >
          Change password
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangePasswordModal;
