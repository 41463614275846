import { useEffect, useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../styles/BottomComparison.css";
import { useDispatch, useSelector } from "react-redux";
import { setCompareLength } from "../redux/slice/compareLengthSlice";
import { useNavigate } from "react-router-dom";
import { deleteCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../redux/slice/compareProductsSlice";
import { allComparisonsProductsDeleteApi } from "Apis/MainApis";
import { MdDelete } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import CategoryProductsModal from "./CategoryProductsModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addCompareProductsApi } from "Apis/MainApis";

function OffCanvasExample({
  show,
  toggleShow,
  handleClose,
  changeOffcanvas,
  setChangeOffCnavas,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = useSelector((state) => state?.user?.data?.id);
  const [compareProducts, setCompareProducts] = useState([]);
  const [updateBottomComparsion, setUpdateBottomComparison] = useState(false);
  const compareProductsLogin = useSelector(
    (state) => state?.compareProducts?.data
  );
  const [popup, setPopup] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (!userId) {
      const comparisonProducts =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];
      setCompareProducts(comparisonProducts);
    }
  }, [changeOffcanvas, userId, updateBottomComparsion]);

  const handleDelete = async (productId) => {
    if (userId) {
      const data = {
        productId: productId,
      };
      await deleteCompareProductsApi(data);
      await dispatch(fetchCompareProducts());
      if (compareProductsLogin?.length === 1) {
        handleClose();
      }
    } else {
      const comparisonProducts =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      const indexToDelete = comparisonProducts.findIndex(
        (product) => product.productId === productId
      );

      if (indexToDelete !== -1) {
        comparisonProducts.splice(indexToDelete, 1);

        localStorage.setItem(
          "comparisonProducts",
          JSON.stringify(comparisonProducts)
        );
      }

      if (comparisonProducts?.length === 0) {
        handleClose();
      }
      dispatch(setCompareLength());
      setChangeOffCnavas(!changeOffcanvas);
    }
  };

  const handleCompareProducts = () => {
    if (userId) {
      if (compareProductsLogin && compareProductsLogin.length > 0) {
        const categorySlug = compareProductsLogin[0].category_slug;
        const categoryId = compareProductsLogin[0].category_id;
        const queryParams = compareProductsLogin
          .map((product, index) => {
            return `pid${index + 1}=${product?.slug}_${product?.productId}`;
          })
          .join("&");
        navigate(`/compare/${categorySlug}_${categoryId}?${queryParams}`);
      }
    } else {
      const comparisonProducts = JSON.parse(
        localStorage.getItem("comparisonProducts")
      );
      if (comparisonProducts && comparisonProducts.length > 0) {
        const categorySlug = comparisonProducts[0].categorySlug;
        const categoryId = comparisonProducts[0].categoryId;
        const queryParams = comparisonProducts
          .map((product, index) => {
            return `pid${index + 1}=${product?.productSlug}_${
              product?.productId
            }`;
          })
          .join("&");
        navigate(`/compare/${categorySlug}_${categoryId}?${queryParams}`);
      }
    }
  };

  const handleClearAll = async () => {
    if (userId) {
      await allComparisonsProductsDeleteApi();
      await dispatch(fetchCompareProducts());
    } else {
      localStorage.removeItem("comparisonProducts");
      setUpdateBottomComparison(!updateBottomComparsion);
      dispatch(setCompareLength());
    }
    handleClose();
  };

  const openCategoryProductsModal = (categoryId) => {
    setCategoryId(categoryId);
    setPopup(true);
  };

  const handleSelect = async (
    newId,
    newSlug,
    categoryId,
    categorySlug,
    productImage,
    productName
  ) => {
    if (userId) {
      const data = {
        productId: newId,
        categoryId: categoryId,
        productSlug: newSlug,
        categorySlug: categorySlug,
      };

      const response = await addCompareProductsApi(data);
      if (response.status === 200) {
        toast(response?.data?.message);
      }
      dispatch(fetchCompareProducts());
    } else {
      const comparisonProducts =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      if (comparisonProducts.length === 4) {
        toast.error("You cannot compare more than four products");
        return;
      }
      if (
        comparisonProducts.some(
          (product) => product && product.productId === newId
        )
      ) {
        toast.error("This product is already added");
        return;
      }
      comparisonProducts.push({
        categorySlug: categorySlug,
        categoryId: categoryId,
        productSlug: newSlug,
        productId: newId,
        productImage: productImage,
        productName: productName,
      });

      localStorage.setItem(
        "comparisonProducts",
        JSON.stringify(comparisonProducts)
      );
      dispatch(setCompareLength());
      setUpdateBottomComparison(!updateBottomComparsion);
      toast("Product Added successfully");
    }
  };

  return (
    <>
      {popup ? (
        <CategoryProductsModal
          popup={popup}
          setPopup={setPopup}
          handleSelect={handleSelect}
          categoryId={categoryId}
        />
      ) : (
        ""
      )}
      <Offcanvas
        className="bottom_compare_offcanvas"
        show={show}
        onHide={handleClose}
        placement="bottom"
        backdrop={false}
        scroll={true}
        style={{
          backgroundColor: "white",
          boxShadow: "#ada8a8 0px 1px 8px 2px",
        }}
      >
        <Offcanvas.Header closeButton closeVariant="black">
          <Offcanvas.Title style={{ fontWeight: "bold", color: "black" }}>
            Your Comparison products
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="row">
            {userId ? (
              <>
                {Array.from({ length: 4 }).map((_, i) => (
                  <div className="col-md-2" key={i}>
                    {compareProductsLogin && i < compareProductsLogin.length ? (
                      <div className="card shadow">
                        <img
                          className="card-img-top"
                          src={compareProductsLogin[i]?.featured_image}
                          alt="Card"
                          style={{ height: "120px" }}
                        />
                        <div className="card-body p-0">
                          <p className="card-text compare_name">
                            {compareProductsLogin[i]?.name}
                          </p>
                        </div>
                        <div
                          className="bottomComparison_cross_icon_box"
                          onClick={() =>
                            handleDelete(compareProductsLogin[i].productId)
                          }
                        >
                          <MdDelete className="bottomComparison_crossIcon" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="card"
                        style={{
                          height: "145px",
                          backgroundColor: "#000000",
                          color: "#dcdcdc",
                        }}
                      >
                        <div
                          className="card-body p-0 d-flex align-items-center justify-content-center"
                          onClick={() =>
                            openCategoryProductsModal(
                              parseInt(compareProductsLogin[0].category_id)
                            )
                          }
                        >
                          <p className="card-text">
                            <FaPlus className="plus_icon" />
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            ) : (
              <>
                {Array.from({ length: 4 }).map((_, i) => (
                  <div className="col-md-2" key={i}>
                    {i < compareProducts.length ? (
                      <div className="card shadow">
                        <img
                          className="card-img-top"
                          src={compareProducts[i]?.productImage}
                          alt="Card"
                          style={{ height: "120px" }}
                        />
                        <div className="card-body p-0">
                          <p className="card-text compare_name">
                            {compareProducts[i]?.productName}
                          </p>
                        </div>
                        <div
                          className="bottomComparison_cross_icon_box"
                          onClick={() =>
                            handleDelete(compareProducts[i].productId)
                          }
                        >
                          <MdDelete className="bottomComparison_crossIcon" />
                        </div>
                      </div>
                    ) : (
                      <div
                        className="card"
                        style={{
                          height: "145px",
                          backgroundColor: "#000000",
                          color: "#dcdcdc",
                        }}
                      >
                        <div
                          className="card-body p-0 d-flex align-items-center justify-content-center"
                          onClick={() =>
                            openCategoryProductsModal(
                              compareProducts[0].categoryId
                            )
                          }
                        >
                          <p className="card-text">
                            <FaPlus className="plus_icon" />
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

            <div className="col-md-4 d-flex align-items-center justify-content-center">
              <button
                className="global_button_white me-2"
                onClick={handleCompareProducts}
              >
                Compare
              </button>
              <button className="global_button_white" onClick={handleClearAll}>
                Clear All
              </button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvasExample;
