import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import "styles/Forms/NewPassword.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Form from "react-bootstrap/Form";
import logo from "Public/navBar_images/logo.png";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { baseURl } from "Utils/BaseURL";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewPassModal = () => {
  const location = useLocation();
  const token = location.state.token;
  console.log("token", token);

  const navigate = useNavigate("");

  const [password, setpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmShowPassword, setconfirmShowPassword] = useState(false);

  const [passError, SetPassError] = useState(false);

  const passwordHandler = (e) => {
    setpassword(e.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // confirm Password -----------------
  const confirmPasswordHandler = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleConfirmPassword = () => {
    setconfirmShowPassword(!confirmShowPassword);
  };

  // confirm Password ------------------

  const confirmpass = async () => {
    if (password === "" || confirmPassword === "") {
      SetPassError("Enter  both fields!");
    } else if (password !== confirmPassword) {
      SetPassError("New Password and Confirm Password Not Match");
    } else {
    }

    const abcd = {
      token: token,
      newPassword: password,
      confirmPassword: confirmPassword,
    };

    try {
      const resetResponse = await axios.post(`${baseURl}reset-password`, abcd);
      if (resetResponse.data.status === "success") {
        navigate("/login");
        toast.success(resetResponse?.data?.message);
      }
    } catch (error) {
      console.log(error);
      toast.warning(error?.resetResponse?.data?.message);
    }
  };

  return (
    <>
      <div className="NewPassModal ">
        <div
          className="row modal d-flex justify-content-center align-items-center"
          style={{ display: "block", position: "initial" }}
        >
          <div className="col-md-5">
            <Modal.Dialog className="confirm_box ">
              <Modal.Body>
                <Form>
                  <div class="d-flex justify-content-center align-items-center pb-3 flex-column">
                    <div class="d-flex justify-content-center align-items-center  avtar ">
                      <img src={logo} alt="img" onClick={() => navigate("/")} />
                    </div>
                    <h4> Meteo Compare</h4>
                  </div>
                  <Form.Group
                    className="mb-3 d-flex flex-column"
                    controlId="formBasicEmail"
                  >
                    <Form.Label className="Lable">New Password</Form.Label>
                    <div className="input_type">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="New Password"
                        value={password}
                        onChange={passwordHandler}
                      />
                      {passError ? (
                        <span className="error text-danger mt-2">
                          {passError}
                        </span>
                      ) : (
                        ""
                      )}
                      <div className="eyes" onClick={handleShowPassword}>
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  </Form.Group>

                  <Form.Group
                    className="mb-3 d-flex flex-column"
                    controlId="formBasicPassword"
                  >
                    <Form.Label className="Lable ">Confirm Password</Form.Label>
                    <div className="input_type">
                      <Form.Control
                        type={confirmShowPassword ? "text" : "password"}
                        placeholder="Conform Password"
                        value={confirmPassword}
                        onChange={confirmPasswordHandler}
                      />
                       {passError ? (
                        <span className="error  text-danger mt-2">
                          {passError}
                        </span>
                      ) : (
                        ""
                      )}
                      <div className="eyes" onClick={handleConfirmPassword}>
                        {confirmShowPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                  </Form.Group>

                  <Button className="conform_button mt-2" onClick={confirmpass}>
                    Confirm Password
                  </Button>
                </Form>
              </Modal.Body>
            </Modal.Dialog>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewPassModal;
