import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { homeProductApi } from "Apis/MainApis";

export const fetchHomeProducts = createAsyncThunk(
  "fetchHomeProducts",
  async (userId) => {
    try { 
      const response = await homeProductApi(userId);
      return response?.data?.data?.homeProduct;
    } catch (error) {
      console.log("Error fetching user:", error.response);
      return null;
    }
  }
);

const fetchUserSlice = createSlice({
  name: "homeProducts",
  initialState: {
    isLoading: false,
    data: [],
    isError: false,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchHomeProducts.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchHomeProducts.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchHomeProducts.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});
export default fetchUserSlice.reducer;
