import React, { useState } from "react";
import "styles/DetailPage/Table.css";

const Table = ({ singleProduct }) => {
  return (
    <>
      <div class="container mt-3 Table">
        <ul class="nav nav-tabs" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#home">
              Description
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#menu1">
              Key Advantages
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" data-bs-toggle="tab" href="#menu2">
              Specifications
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div id="home" class="container tab-pane active">
            {singleProduct?.product_file === "" ? (
              <div style={{ height: "200px" }}></div>
            ) : (
              <p className="title">
                <iframe
                  className="table_iframe"
                  style={{ height: "500px", width: "100%" }}
                  src={singleProduct?.product_file}
                  title="Product Description"
                ></iframe>
              </p>
            )}
          </div>
          <div id="menu1" class="container tab-pane fade">
            <div className="Detail_page_Table">
              <table>
                <tbody>
                  <tr>
                    <th className="Detail_page_Table_heading">
                      Key Advantages
                    </th>
                  </tr>
                  {singleProduct?.advantages?.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td>{data.advantage}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div id="menu2" class="container tab-pane fade">
            <div className="Detail_page_Table">
              <table>
                <tbody>
                  <tr>
                    <th className="Detail_page_Table_heading">
                      Specifications
                    </th>
                  </tr>
                  {singleProduct?.specifications?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {item?.title}: {item?.desc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="container">
        <ul class="nav nav-tabs">
          <li class="active">
            <a data-toggle="tab" href="#home">
              Description
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#menu1">
              Key Advantages
            </a>
          </li>
          <li>
            <a data-toggle="tab" href="#menu2">
              Specifications
            </a>
          </li>
        </ul>

        <div class="tab-content">
          <div id="home" class="tab-pane fade in active">
            {singleProduct?.product_file === "" ? (
              ""
            ) : (
              <p className="title">
                <iframe
                  className="table_iframe"
                  // style={{ height: "500px", width: "100%", overflow: "hidden" }}
                  src={singleProduct?.product_file}
                  title="Product Description"
                ></iframe>
              </p>
            )}
          </div>
          <div id="menu1" class="tab-pane fade">
            <div className="Detail_page_Table">
              <table>
                <tbody>
                  <tr>
                    <th className="Detail_page_Table_heading">
                      Key Advantages
                    </th>
                  </tr>
                  {singleProduct?.advantages?.map((data, i) => {
                    return (
                      <tr key={i}>
                        <td>{data.advantage}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div id="menu2" class="tab-pane fade">
            <div className="Detail_page_Table">
              <table>
                <tbody>
                  <tr>
                    <th className="Detail_page_Table_heading">
                      Specifications
                    </th>
                  </tr>
                  {singleProduct?.specifications?.map((item, i) => {
                    return (
                      <tr key={i}>
                        <td>
                          {item?.title}: {item?.desc}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}

      {/* <div className="Detail_page_Table">
        <table>
          <tbody>
            <tr>
              <th className="Detail_page_Table_heading">Key Advantages</th>
            </tr>
            {singleProduct?.advantages?.map((data, i) => {
              return (
                <tr key={i}>
                  <td>{data.advantage}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className="mt-4">
          <tbody>
            <tr>
              <th className="Detail_page_Table_heading">Specifications</th>
            </tr>
            {singleProduct?.specifications?.map((item, i) => {
              return (
                <tr key={i}>
                  <td>{item?.title}: {item?.desc}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
    </>
  );
};

export default Table;
