import React, { useState, useEffect } from "react";
import Footer from "Sections/HomeSections/Footer";
import NavBar from "Sections/HomeSections/NavBar";
import { IoPlay, IoClose } from "react-icons/io5";
import "../../styles/video.css";
import video from "Video/RadarVideo.mp4";
import radarImg from "Video/radra_img.jpg";
import radarimgtwo from "Video/radra_img2.jpg";
import videoTwo from "Video/radarVideoTwo.mp4";
import videoBanner from "Video/videoSecBanner.mp4";
import { fetchVideoApi } from "Apis/MainApis";
import { useNavigate } from "react-router-dom";
import { FaAngleRight } from "react-icons/fa";
import { IoMdHome } from "react-icons/io";

const Videos = () => {
  const [isPopupVisible1, setPopupVisible1] = useState(false);
  const [videos, setVideos] = useState([]);
  const [singleVideo, setSingleVideo] = useState("");
  const navigate = useNavigate();

  const togglePopup1 = (slug, videoLink) => {
    navigate(`/videos/${slug}`);
    setPopupVisible1(!isPopupVisible1);
    setSingleVideo(videoLink);
  };

  const togglePopup2 = () => {
    navigate("/videos");
    setPopupVisible1(!isPopupVisible1);
  };

  useEffect(() => {
    async function fetchVideo() {
      const response = await fetchVideoApi();
      setVideos(response.data.data.categories);
    }
    fetchVideo();
  }, []);
  return (
    <>
      <NavBar />
      <div className="global_routing_Navbar_bottom">
        <p className="d-flex align-items-center m-0">
          <IoMdHome className="fs-5" /> <FaAngleRight className="fs-6" />
          <span style={{ cursor: "pointer" }}>
            Videos
          </span>
        </p>
      </div>
      <div className="videoHeader mt-3">
        <section>
          <div class="overlay-wcs"></div>
          <video
            playsinline="playsinline"
            autoplay="autoplay"
            muted="muted"
            loop="loop"
          >
            <source src={videoBanner} type="video/mp4" />
            <source src={videoBanner} type="video/mp4" />
            <source src={videoBanner} type="video/mp4" />
          </video>
        </section>
      </div>
      {/* end------------------------------ */}
      <div className="main_videoSec bg-body">
        <div className="videoHeading d-flex  justify-content-between align-items-center pb-3">
          <h3 className="m-0 fw-bold">Video Section</h3>
        </div>
        <div className="row gy-3">
          {videos?.map((video, i) => (
            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-6">
              <div className="videoPlay">
                <div
                  className="videoThumbnail"
                  onClick={() => togglePopup1(video?.video_slug, video?.video)}
                >
                  <img src={video.video_thumbnail} alt="Play Video" />
                  <div className="video_icon_box">
                    <IoPlay className="icon" />
                  </div>
                </div>
                {isPopupVisible1 && (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="videoPopup" style={{ height: "400px" }}>
                      <div
                        className="closeIcon d-flex justify-content-end"
                        onClick={togglePopup2}
                      >
                        <IoClose className="closebtn_icon" />
                      </div>
                      <video
                        width="100%"
                        playsinline=""
                        loop=""
                        muted=""
                        autoPlay=""
                        controls
                        style={{ objectFit: "fill" }}
                      >
                        <source
                          src={singleVideo}
                          type="video/mp4"
                          style={{ height: "400px" }}
                        />
                      </video>
                    </div>
                  </div>
                )}
                <div className="video_content px-2 py-3">
                  <h5 className="m-0 pb-1 fw-bold ">{video?.video_title}</h5>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Videos;
