import React, { useEffect, useState, useRef } from "react";
import "styles/NavBar.css";
import { FaBars } from "react-icons/fa6";
import { IoMdSearch } from "react-icons/io";
import { LuArrowLeftRight } from "react-icons/lu";
import { FaRegHeart } from "react-icons/fa";
import { FaRegUser } from "react-icons/fa";
import imgLogo from "Public/navBar_images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { fetchParentCategories } from "Apis/MainApis";
import { useSelector } from "react-redux";
import { searchApi } from "Apis/MainApis";
import LoginModal from "../../Authorization/LoginModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NavBar = ({ comparisonProductCount }) => {
  const [parentCategories, setParentCategories] = useState([]);
  const [searchApiProduct, setSearchApiProduct] = useState([]);
  const [showSearchProducts, setShowSearchProducts] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState();
  const [open, setOpen] = useState(false);
  const [page, setPage] = useState(1);

  const user = useSelector((state) => state?.user?.data);
  const wishlist = useSelector((state) => state?.wishlist?.data);
  const compareLength = useSelector((state) => state.compareLength.length);
  const comparisonProductsLogin = useSelector(
    (state) => state?.compareProducts?.data
  );

  const navigate = useNavigate();

  const dropdownRef = useRef(null);

  const openHandler = () => {
    setOpen(!open);
  };

  useEffect(() => {
    feactparentcategories();
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowSearchProducts(false);
    }
  };

  const feactparentcategories = () => {
    fetchParentCategories((callbackData) => {
      setParentCategories(callbackData?.data?.categories);
    });
  };

  const handlerSearch = (e) => {
    const query = e.target.value;
    if (query === "") {
      setSearchApiProduct([]);
      setShowSearchProducts(false);
      return;
    }
    setShowSearchProducts(true);
    setLoading(true);
    searchApi(user?.id || "", query, page, setLoading, (callbackData) => {
      setSearchApiProduct(callbackData?.data?.products);
    });
  };

  const [openCompare, setOpenCompare] = useState();
  {
  }

  const compareProducts = () => {
    if (user?.id) {
      if (comparisonProductsLogin && comparisonProductsLogin.length > 0) {
        const categorySlug = comparisonProductsLogin[0].category_slug;
        const categoryId = comparisonProductsLogin[0].category_id;
        const queryParams = comparisonProductsLogin
          .map((product, index) => {
            return `pid${index + 1}=${product?.slug}_${product?.productId}`;
          })
          .join("&");
        navigate(`/compare/${categorySlug}_${categoryId}?${queryParams}`);
      } else {
        toast.warning("Please select atleast one product for comparison.");
      }
    } else {
      const comparisonProducts = JSON.parse(
        localStorage.getItem("comparisonProducts")
      );
      if (comparisonProducts && comparisonProducts.length > 0) {
        const categorySlug = comparisonProducts[0].categorySlug;
        const categoryId = comparisonProducts[0].categoryId;
        const queryParams = comparisonProducts
          .map((product, index) => {
            return `pid${index + 1}=${product?.productSlug}_${
              product?.productId
            }`;
          })
          .join("&");
        navigate(`/compare/${categorySlug}_${categoryId}?${queryParams}`);
      } else {
        toast.warning("Please select atleast one product for comparison.");
      }
    }
  };

  const handleWishlist = () => {
    if (user?.id) {
      navigate("/wishlist");
    } else {
      setShowModal(true);
    }
  };

  return (
    <>
      <LoginModal showModal={showModal} setShowModal={setShowModal} />
      <div className="nav_bar">
        <div className="deskTopNavbar">
          <div className="row d-flex align-items-center justify-content-between ">
            <div className="col-lg-2 col-md-3  col-sm-12 col-12">
              <div
                className="navbar-logoSec d-flex"
                onClick={() => navigate("/")}
              >
                <img src="/logo.png" alt="logo" />
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-3 col-5 ">
              <div
                className="d-flex justify-content-center align-items-center gap-1 navbar-categores_Sec position-relative"
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
                onClick={() => setOpen(!open)}
              >
                <FaBars className="Categories_bar_icon" />
                <p className="m-0" style={{ cursor: "pointer" }}>
                  Categories
                </p>

                {open && (
                  <div
                    className="shadow-lg"
                    id="categoriesDropdown"
                    style={{
                      position: "absolute",
                      top: "20px",
                      background: "white",
                      color: "black",
                      zIndex: "99",
                    }}
                  >
                    <ul className="p-0 list-unstyled">
                      {parentCategories?.map((data, i) => {
                        return (
                          <li
                            key={i}
                            onClick={() =>
                              navigate(`/${data?.slug}_${data?.id}`)
                            }
                          >
                            {data.name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-5 col-md-4 col-sm-8 col-7  my-sm-3 my-3">
              <div className="navbar-searchSec">
                <input
                  type="text"
                  placeholder="Find Best Product"
                  onChange={handlerSearch}
                />
                <IoMdSearch className="navbar-search_icon" />
                <div className="dropdown" ref={dropdownRef}>
                  {showSearchProducts && searchApiProduct?.length === 0 ? (
                    <>
                      {loading ? (
                        <ul>
                          <li className="list-unstyled">Loading....</li>
                        </ul>
                      ) : (
                        <ul>
                          <li className="list-unstyled">No Data Found!!!</li>
                        </ul>
                      )}
                    </>
                  ) : (
                    showSearchProducts &&
                    searchApiProduct?.map((item, i) => {
                      return (
                        <ul key={i}>
                          <li
                            className="list-unstyled"
                            onClick={() => {
                              navigate(
                                `/detail-page/${item?.slug}_${item?.id}`,
                                window.scrollTo(0, 0)
                              );
                            }}
                          >
                            {item.name}
                          </li>
                        </ul>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-6 col-9">
              <div className="d-flex justify-content-around align-items-center navbar-right_section  ">
                <div>
                  <div
                    className="navbar-comparer_Sec position-relative"
                    onClick={compareProducts}
                  >
                    <LuArrowLeftRight className="icons Categories_bar_icon" />
                    <p className="m-0" style={{ cursor: "pointer" }}>
                      Compare
                    </p>
                    {user?.id ? (
                      <>
                        {comparisonProductsLogin &&
                          comparisonProductsLogin.length > 0 && (
                            <div className="numbers">
                              <p>{comparisonProductsLogin.length}</p>
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        {compareLength > 0 && (
                          <div className="numbers">
                            <p>{compareLength}</p>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
                <div className="wishlistIcon">
                  <Link
                    className="text-white text-decoration-none"
                    onClick={() => handleWishlist()}
                  >
                    <FaRegHeart className="icons" />
                    <p className="m-0">Wishlist</p>
                  </Link>
                  {wishlist !== undefined && wishlist?.length > 0 ? (
                    <>
                      <div className="numbers">
                        <p>{wishlist?.length}</p>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {user ? (
                    <Link
                      to="/profile"
                      className="text-white text-decoration-none"
                    >
                      <img
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50%",
                        }}
                        src={
                          user?.image
                            ? user?.image
                            : "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?size=626&ext=jpg&ga=GA1.1.87170709.1707523200&semt=ais"
                        }
                        alt="image"
                      />
                      <p className="m-0"> Profile </p>
                    </Link>
                  ) : (
                    <Link
                      to="/login"
                      className="text-white text-decoration-none"
                    >
                      <FaRegUser className="icons" />
                      <p className="m-0"> Sign Up / Sign In </p>
                    </Link>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-0 col-sm-6 col-3  MobileNavBar ">
              <div className="offcanvas offcanvas-end " id="demo">
                <div className="offcanvas-header">
                  <img src={imgLogo} alt="img" onClick={() => navigate("/")} />
                  <button
                    type="button"
                    className="btn-close btn-dark fw-bold"
                    data-bs-dismiss="offcanvas"
                  ></button>
                </div>
                <div className="offcanvas-body lh-lg">
                  <Link
                    to="/portfolio"
                    className="text-decoration-none text-dark"
                  >
                    <p className="m-0 text-center">Supplier Portfolio</p>
                  </Link>
                  {/* <Link to="/news" className="text-decoration-none text-dark">
                    <p className="m-0  text-center">Industry News</p>
                  </Link> */}

                  <Link
                    to="/aboutus"
                    className="text-decoration-none text-dark"
                  >
                    <p className="m-0 text-center">About us</p>
                  </Link>

                  <Link
                    to="/contactus"
                    className="text-decoration-none text-dark"
                  >
                    <p className="m-0 text-center">Contact Us</p>
                  </Link>

                  <Link to="/terms" className="text-decoration-none text-dark">
                    <p className="m-0 text-center">Terms of use</p>
                  </Link>

                  <Link
                    to="/privacypolicy"
                    className="text-decoration-none text-dark"
                  >
                    <p className="m-0 text-center">Privacy Policy</p>
                  </Link>

                  <Link to="/news" className="text-decoration-none text-dark">
                    <p className="m-0 text-center">News</p>
                  </Link>
                  <Link to="/videos" className="text-decoration-none text-dark">
                    <p className="m-0 text-center" id="video_sec">
                      Videos
                    </p>
                  </Link>
                </div>
              </div>
              <div className="row d-flex justify-content-between  align-items-center">
                <div className=" d-flex justify-content-end">
                  <FaBars
                    className="text-white fs-4"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#demo"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Mobile menu Bar -----------------------------------------------p  */}

        <hr className="navbar-hrLine m-0 " />
        <div className="nav_bar-bottom_sec ">
          <div className="row d-flex align-items-center justify-content-between">
            <div className="col-lg-4 col-md-3 col-sm-2"></div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="d-flex align-items-center justify-content-between nav_bar-bottom_sec-center">
                {parentCategories?.map((data, i) => {
                  return (
                    <div className="d-flex justify-content-between" key={i}>
                      <p
                        className="m-0"
                        onClick={() => navigate(`/${data?.slug}_${data?.id}`)}
                      >
                        {data.name}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavBar;
