import React from 'react';
import "styles/Header.css"
import Header_Banner from "Public/Header_banner/Header_Banner.png"

const Header = () => {
  return (
    <>
      <div className='Header_banner'>
      <img src={Header_Banner} alt="img" />
      </div>
    </>
  )
}

export default Header
