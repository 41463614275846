import React, { useState } from "react";
import "styles/DetailPage/DetailRecentView.css";
import filterproduct_image from "Public/filterProduct_img/filterproduct_image.png";
import { useNavigate } from "react-router-dom";
import { FaRegHeart } from "react-icons/fa";
import Button from "react-bootstrap/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addToWishlist,
  fetchWishlist,
  removeFromWishlist,
} from "../../redux/slice/fetchWishlistSlice";
import { FaHeart } from "react-icons/fa6";
import { fetchHomeProducts } from "../../redux/slice/fetchHomeProductsSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setCompareLength } from "../../redux/slice/compareLengthSlice.js";
import { addCompareProductsApi } from "Apis/MainApis";
import { fetchCompareProducts } from "../../redux/slice/compareProductsSlice";
import OffCanvasExample from "Utils/BottomComparison";

const DetailRecentView = ({ recentData, recentProduct }) => {
  // console.log("recentData", recentData);
  const user = useSelector((state) => state?.user?.data);
  const [changeOffcanvas, setChangeOffCnavas] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleWishlist = async (id, wish) => {
    if (wish === "yes") {
      await dispatch(removeFromWishlist(id));
    } else {
      await dispatch(addToWishlist(id));
    }
    await dispatch(fetchWishlist());
    recentProduct();
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleShow = () => setShow(true);

  const handleCompare = async (item) => {
    if (user?.id) {
      const data = {
        productId: item.productId,
        categoryId: item.categoryId,
        productSlug: item.catSlug,
        categorySlug: item.slug,
      };
      const response = await addCompareProductsApi(data);
      if (response.status === 200) {
        toast(response?.data?.message);
        toggleShow();
      }
      dispatch(fetchCompareProducts());
    } else {
      let compareData =
        JSON.parse(localStorage.getItem("comparisonProducts")) || [];

      if (
        compareData.length > 0 &&
        compareData[0].categoryId != item.categoryId
      ) {
        toast.warning("You can only compare products from the same category.");
        return;
      }

      if (compareData.length >= 4) {
        toast.warning("You can only compare a maximum of four products.");
        return;
      }

      const existingProduct = compareData.find(
        (product) => product.productId == item.productId
      );

      if (existingProduct) {
        toast("This product is already added to comparison.");
        toggleShow();
        return;
      }

      compareData.push({
        productId: item.productId,
        categoryId: item.categoryId,
        categorySlug: item.catSlug,
        productSlug: item.slug,
        productImage: item.featured_image,
        productName: item?.name,
      });

      toast("Product Added");

      localStorage.setItem("comparisonProducts", JSON.stringify(compareData));
      dispatch(setCompareLength());
      toggleShow();
    }
    setChangeOffCnavas(!changeOffcanvas);
  };

  return (
    <>
      <OffCanvasExample
        show={show}
        toggleShow={toggleShow}
        handleClose={handleClose}
        changeOffcanvas={changeOffcanvas}
        setChangeOffCnavas={setChangeOffCnavas}
      />
      {recentData?.length > 0 ? (
        <>
          <div className="DetailRecentView ">
            <div className="DetailPage_FilterProduct ">
              <h3 className="d-flex fw-bold px-3  m-0"> Recently Viewed</h3>
            </div>
            <div className="row">
              {recentData.map((item, i) => {
                return (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-6 px-4 mt-5"
                    key={i}
                  >
                    <div className="DetailRecentView-card  ">
                      <img
                        src={item.featured_image}
                        alt="img"
                        className="w-100 detailView_image"
                        onClick={() => {
                          navigate(
                            `detail-page/${item?.slug}_${item?.productId}`,
                            window.scrollTo(0, 0)
                          );
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <div className="DetailRecentView-details">
                        <div className="d-flex justify-content-between align-items-center DetailRecentViewt-details-heart">
                          <p className="m-0 text-start recentlyview_cardtext">
                            {item?.name}
                          </p>
                          {/* {item.wishlist === "no" ? (
                            <>
                              <FaRegHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          ) : (
                            <>
                              <FaHeart
                                className="heart_icon"
                                onClick={() =>
                                  handleWishlist(item?.id, item?.wishlist)
                                }
                              />
                            </>
                          )} */}
                        </div>
                        <p className="text-start  recentlyview_cardtext">
                          {item?.description}
                        </p>
                        <div className="d-flex justify-content-between align-items-center">
                          <Button
                            className="global_button_white"
                            onClick={() => {
                              navigate(
                                `detail-page/${item?.slug}_${item?.productId}`,
                                window.scrollTo(0, 0)
                              );
                            }}
                          >
                            Details
                          </Button>
                          <Button
                            className="global_button_colored"
                            onClick={() => handleCompare(item)}
                          >
                            Compare
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default DetailRecentView;
