import { configureStore } from "@reduxjs/toolkit";
import fetchWishlistSlice from "./slice/fetchWishlistSlice";
import fetchUserSlice from "./slice/fetchUserSlice";
import fetchHomeProductsSlice from "./slice/fetchHomeProductsSlice";
import compareLengthSlice from "./slice/compareLengthSlice";
import compareProductsSlice from "./slice/compareProductsSlice";

export const store = configureStore({
  reducer: {
    wishlist: fetchWishlistSlice,
    user: fetchUserSlice,
    homeProducts: fetchHomeProductsSlice,
    compareLength: compareLengthSlice,
    compareProducts: compareProductsSlice,
  },
});

export default store;
