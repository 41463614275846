import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const compareLengthSlice = createSlice({
  name: "comparelength",
  initialState: {
    length: 0,
  },
  reducers: {
    setCompareLength: (state, action) => {
      const comparisonProductsData = localStorage.getItem("comparisonProducts");
      const comparisonProducts = JSON.parse(comparisonProductsData || "[]");
      const comparisonProductCount = comparisonProducts?.length;
      state.length = comparisonProductCount;
    },
  },
});

export const { setCompareLength } = compareLengthSlice.actions;
export default compareLengthSlice.reducer;
